import React from 'react';
import Carousel from 'react-multi-carousel';
import { Typography, Box, Paper } from '@mui/material';
import 'react-multi-carousel/lib/styles.css';
import '../css/Homepage.css';
import img from '../assets/holloween.png';
import img2 from '../assets/halloween2.png';
import img3 from '../assets/halloween3.png';
import img4 from '../assets/halloween4.png';
import img5 from '../assets/halloween5.png';
import img6 from '../assets/halloween6.png';
import img7 from '../assets/halloween7.png';
import images from '../images'
// import whatever images we need for the carousel

const NewHome = () => {
    const carouselItems = [
        {
            name: "Kelly Brooks",
            description: "Sacred Cow",
            img: img
        },
        {
            name: "Anthony and Sarikas",
            description: "White Chicks",
            img: img2
        },
        {
            name: "Vincent Cortazar",
            description: "Sal",
            img: img3
        },
        {
            name: "Danny Alvarado",
            description: "Lone Ranger",
            img: img7
        },
        {
            name: "Mike Fischer",
            description: "Deal Fairy",
            img: img4
        },
        {
            name: "John Rugolo",
            description: "Baby Rugolo",
            img: img5
        },
        {
            name: "Mike Castillo",
            description: "Jackie Moon",
            img: img6
        }
        // Add and remove items as needed
    ];

    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1024 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 1024, min: 768 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 768, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };

    return (
        <Box className="hero-container">
            <Box className="overlay">
                <Box className="content">
                    <Typography variant="h2" component="h2" gutterBottom sx={{ textShadow: 'black 3px 0 10px', fontWeight: 'bold' }}>
                        Medigap Life
                    </Typography>
                    <Typography variant="h5" component="p" sx={{ textShadow: 'black 2px 0 10px', maxWidth: '35vw' }}>
       To empower Medicare beneficiaries with thoughtful healthcare choices, we aim to lead our industry while staying true to our customer-centric values. 
                    </Typography>
                </Box>
                <Box className="carousel-container">
                    <Carousel
                        responsive={responsive}
                        infinite={true}
                        autoPlay={true}
                        autoPlaySpeed={3000}
                        showDots={true}
                        arrows={true}
                        >
                        {carouselItems.map((item, i) => (
                            <Paper key={i} className="carousel-item" sx={{ paddingRight: "2vw", background: "none" }}>
                                <img
                                    src={item.img}
                                    alt={item.name}
                                    className="carousel-img"
                                    loading="lazy"
                                    />
                                <Box className="carousel-caption">
                                    <Typography variant="h6">{item.name}</Typography>
                                    <Typography>{item.description}</Typography>
                                </Box>
                            </Paper>
                        ))}
                        {Object.keys(images).map((key, i) => (
                            <Paper key={i} className="carousel-item" sx={{ paddingRight: "2vw", background: "none" }}>
                                <img
                                    src={images[key]}
                                    alt={key}
                                    className="carousel-img"
                                    loading="lazy"
                                    />
                                <Box className="carousel-caption">
                                    <Typography variant="h6"></Typography>
                                    <Typography></Typography>
                                </Box>
                            </Paper>
                        ))}
                    </Carousel>
                </Box>
            </Box>
        </Box>
    );
};

export default NewHome;
