import React, { createContext, useState } from 'react';
import Cookies from 'js-cookie';

const ThemeContext = createContext();

export const ThemeToggleProvider = ({ children }) => {
    const [isDarkTheme, setIsDarkTheme] = useState(Cookies.get('darkmode') === 'true' ? true : false);

const toggleTheme = () => {
    setIsDarkTheme(prevTheme => {
        const newTheme = !prevTheme;
        Cookies.set('darkmode', newTheme ? 'true' : 'false');
        return newTheme;
    });
};

    return (
        <ThemeContext.Provider value={{ isDarkTheme, toggleTheme }}>
            {children}
        </ThemeContext.Provider>
    );
};

export default ThemeContext;
