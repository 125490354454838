import './App.css';
import Footer from './components/Footer';
import Nav from './components/Nav';
import { useEffect, useState } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Container from '@mui/material/Container';
import instance from './axios/axios';
import useAuth from './hooks/useAuth';
import Login from './components/Login';

const StyledContainer = styled(Container)({
    minHeight: '100vh',
    minWidth: '80%',
    fontFamily: 'Overpass Mono',
});

function App() {
    const { auth, setAuth } = useAuth();

    useEffect(() => {
        const validateSession = async () => {
            try {
                const response = await instance.post('/validate', {}, { withCredentials: true });
                if (response.status !== 200) {
                    setAuth({});
                }
            } catch (error) {
                console.error('Session validation failed:', error);
                setAuth({});
            }
        };

        const interval = setInterval(validateSession, 120000);
        return () => clearInterval(interval);
    }, [setAuth]);

    return (
        <StyledContainer>
        {auth?.auth && <Nav />}
            <div style={{ minHeight: "65vh"  }}>
                {auth?.auth ? <Outlet /> : <Login />}
            </div>
        <Footer />
        </StyledContainer>
    );
}

export default App;
