import React from 'react';
import ReactApexChart from 'react-apexcharts';

const MyLineChart = ({ data, annotatedXValue }) => {
    const {xAxis, series} = data;
    const annotatedWidth = (xAxis.length / 8) * 1000000;
    const seriesData = series.map(s => {
        return {
            name: s.label,
            data: s.data.map((value, index) => ({
                x: new Date(xAxis[index]).getTime(),
                y: value
            }))
        };
    });

    const annotations = annotatedXValue && xAxis.includes(annotatedXValue) ? [{
        x: new Date(annotatedXValue).getTime() - annotatedWidth,
        x2: new Date(annotatedXValue).getTime() + annotatedWidth,
        borderColor: '#FF4560',
        fillColor: '#FF4560',
        opacity: 0.4,
        strokeDasharray: 0,
        strokeWidth: 3,
        label: {
            borderColor: '#FF4560',
            style: {
                color: '#fff',
                background: '#FF4560'
            },
        },
    }] : [];

    const chartOptions = {
        chart: {
            events: {
                updated: function (chartContext, config) {
                    const yaxisLabels = document.querySelectorAll('.apexcharts-yaxis text');

                    yaxisLabels.forEach(function (label) {
                        label.setAttribute('fill', '#fff');
                    })
                }
            },
            type: 'line',
            height: 'auto',
            width: '100%',
            toolbar: {
                show: false,
                tools: {
                    download: false,
                    selection: true,
                    zoom: true,
                    zoomin: true,
                    zoomout: true,
                    pan: true,
                    reset: true | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
                autoSelected: 'zoom'
            },
            background: '#333',
            zoom: {
                enabled: false,
                type: 'x',
            },
        },
        colors: ['#0af', '#0f0', '#ff0', '#f0f'],
        xaxis: {
            type: 'datetime',
            labels: {
                datetimeUTC: true,
                style: {
                    colors: '#fff'
                },
            },
            axisTicks: {
                color: '#fff',
                show: true,
            },
            axisBorder: {
                color: '#fff',
                show: false,
            },
            tooltip: {
                enabled: false,
            },
        },
        legend: {
            show: true,
            labels: {
                colors: '#fff',
                useSeriesColors: false
            }
        },
        fill: {
            opacity: 1,
            colors: ['#0af', '#0f0', '#ff0', '#f0f'],
        },
        yaxis: {
            labels: {
                style: {
                    color: '#fff',
                    fontSize: '12px'
                }
            }
        },
        grid: {
            xaxis: {
                lines: {
                    show: false
                },
            },
            yaxis: {
                lines: {
                    show: true
                }
            },
            padding: {
                top: 0,
                right: 40,
                bottom: 0,
                left: 40
            },
            borderColor: '#555'
        },
        tooltip: {
            theme: 'dark',
            x: {
                format: 'MMM dd yyyy'
            },
            enabled: true,
        },
        markers: {
            size: 6,
            colors: ['#0af', '#0f0', '#ff0', '#f0f'],
            strokeColors: '#333',
            strokeWidth: 1,
            hover: {
                size: 10,
                sizeOffset: 0
            }
        },
        stroke: {
            curve: 'smooth',
            width: 3,
            colors: ['#0af', '#0f0', '#ff0', '#f0f']
        },
        annotations: {
            xaxis: annotations
        }
    };


    return (
        <div id="chart">
            <ReactApexChart options={chartOptions} series={seriesData} type="line" height={350}/>
        </div>
    );
};

export default MyLineChart;
