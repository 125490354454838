import React, { useEffect, useState } from 'react';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Snackbar, Alert, Checkbox } from '@mui/material';
import Table from '@mui/material/Table';
import Fade from '@mui/material/Fade';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import instance from '../axios/axios';
import StatusIcon from './StatusIcon';

function UserManagement() {
    const [users, setUsers] = useState([]);
    const [addUser, setAddUser] = useState(false);
    const [userName, setUserName] = useState('');
    const [userEmail, setUserEmail] = useState('');
    const [userPassword, setUserPassword] = useState('');
    const [userConfirmPassword, setUserConfirmPassword] = useState('');
    const [submitDisabled, setSubmitDisabled] = useState(true);
    const [editUserReportsDialogOpen, setEditUserReportsDialogOpen] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [userReports, setUserReports] = useState([]);
    const [availableReports, setAvailableReports] = useState([]);
    const [selectedReportToAdd, setSelectedReportToAdd] = useState('');
    const [userRoles, setUserRoles] = useState([]);
    const [allRoles, setAllRoles] = useState([]);
    const [selectedRoleToAdd, setSelectedRoleToAdd] = useState('');
    const [editUserRolesDialogOpen, setEditUserRolesDialogOpen] = useState(false);
    const [xferCRMUsers, setXferCRMUsers] = useState(false);
    const [crmUsers, setCrmUsers] = useState([]);
    const [refreshUsers, setRefreshUsers] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [editUserDialogOpen, setEditUserDialogOpen] = useState(false);
    const [userAttributes, setUserAttributes] = useState([]);
    const [id, setId] = useState('');
    const [email, setEmail] = useState('');
    const [isAdmin, setIsAdmin] = useState(false);
    const [username, setUsername] = useState('');
    const [changePasswordBox, setChangePasswordBox] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [changePasswordDisabled, setChangePasswordDisabled] = useState(true);
    const [editUserApplicationsDialogOpen, setEditUserApplicationsDialogOpen] = useState(false);
    const [userApplications, setUserApplications] = useState([]);
    const [availableApplications, setAvailableApplications] = useState([]);
    const [selectedApplicationToAdd, setSelectedApplicationToAdd] = useState('');
    const [checked, setChecked] = useState(false);

    const handleToggleAddUser = () => {
        setAddUser(!addUser);
    }

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleAddReportToUser = async () => {
        if (!selectedReportToAdd) {
            handleSnackbarOpen('Please select a report to add.', 'warning');
            return;
        }
    
        try {
            const response = await instance.post(`/access/add`, { user_id: selectedUser.id, report_id: selectedReportToAdd }, { withCredentials: true });
            if (response.status === 200) {
                alert('Report added successfully');
                handleEditUserReports(selectedUser);
            } else {
                handleSnackbarOpen('Failed to add report', 'error');
            }
        } catch (error) {
            console.error('Error adding report:', error);
            handleSnackbarOpen('Error adding report', 'error');
        }
    };

    const handleSetChangePassword = () => {
        setChangePasswordBox(true);
    }

    const handleChangePassword = async (id) => {
        try {
            const response = await instance.post(`/users/${id}/edit`, { password: password }, { withCredentials: true });
            if (response.status === 200) {
                handleSnackbarOpen('Password changed successfully', 'success');
                setChangePasswordBox(false);
                setPassword('');
                setConfirmPassword('');
            } else {
                handleSnackbarOpen('Failed to change password', 'error');
            }
        } catch (error) {
            console.error('Error changing password:', error);
            handleSnackbarOpen('Error changing password', 'error');
        }
    }

    const handleAddRoleToUser = async () => {
        if (!selectedRoleToAdd) {
            handleSnackbarOpen('Please select a role to add.', 'warning');
            return;
        } else {
            try {
                const response = await instance.post(`/users/roles/members/add`, { user_id: selectedUser.id, role_id: selectedRoleToAdd }, { withCredentials: true });
                if (response.status === 200) {
                    handleSnackbarOpen('Role added successfully', 'success');
                    handleEditUserRoles(selectedUser);
                } else {
                    handleSnackbarOpen('Failed to add role', 'error');
                }
            } catch (error) {
                console.error('Error adding role:', error);
                handleSnackbarOpen('Error adding role', 'error');
            }
        }
    };

    const handleAddApplicationToUser = async () => {
        if (!selectedApplicationToAdd) {
            handleSnackbarOpen('Please select an application to add.', 'warning');
            return;
        } else {
            try {
                const response = await instance.post(`/access/apps/add`, { user_id: selectedUser.id, application_id: selectedApplicationToAdd }, { withCredentials: true });
                if (response.status === 200) {
                    handleSnackbarOpen('Application added successfully', 'success');
                    handleEditUserApplications(selectedUser);
                } else {
                    handleSnackbarOpen('Failed to add application', 'error');
                }
            } catch (error) {
                console.error('Error adding application:', error);
                handleSnackbarOpen('Error adding application', 'error');
            }
        }
    };

    const handleXferCRMUsers = async () => {
        try {
            setXferCRMUsers(true);
            const response = await instance.get('/users/crm/all', { withCredentials: true });
            if (response.status === 200) {
                const filteredUsers = response.data.data.users.filter((crmUser) => !users.some((user) => user.username === crmUser.username));
                setCrmUsers(filteredUsers);
            } else {
                console.error('Failed to fetch CRM users');
                handleSnackbarOpen('Failed to fetch CRM users', 'error');
            }
        } catch (error) {
            console.error('Error fetching CRM users:', error);
            handleSnackbarOpen('Error fetching CRM users', 'error');
        }
    };

    const handleTransferCRMUser = async (crmUserId) => {
        try {
            const response = await instance.post('/users/xfer', { crm_user_id: crmUserId }, { withCredentials: true });
            if (response.status === 200) {
                handleSnackbarOpen('User transferred successfully', 'success');
                const newUsers = users.filter((user) => user.id !== crmUserId);
                setUsers(newUsers);
                const newCRMUsers = crmUsers.filter((crmUser) => crmUser.id !== crmUserId);
                setCrmUsers(newCRMUsers);
                setRefreshUsers(true);
            } else {
                handleSnackbarOpen('Failed to transfer user', 'error');
            }
        } catch (error) {
            console.error('Error transferring user:', error);
            handleSnackbarOpen('Error transferring user', 'error');
        }
    };

    const handleRemoveReport = async (reportId) => {
        try {
            const response = await instance.post(`/access/delete`, { user_id: selectedUser.id, report_id: reportId }, { withCredentials: true });
            if (response.status === 200) {
                handleSnackbarOpen('Report removed successfully', 'success');
                handleEditUserReports(selectedUser);
            } else {
                handleSnackbarOpen('Failed to remove report', 'error');
            }
        } catch (error) {
            console.error('Error removing report:', error);
            handleSnackbarOpen('Error removing report', 'error');
        }
    };

    const handleRemoveApplication = async (applicationId) => {
        try {
            const response = await instance.post(`/access/apps/delete`, { user_id: selectedUser.id, application_id: applicationId }, { withCredentials: true });
            if (response.status === 200) {
                handleSnackbarOpen('Application removed successfully', 'success');
                handleEditUserApplications(selectedUser);
            } else {
                handleSnackbarOpen('Failed to remove application', 'error');
            }
        } catch (error) {
            console.error('Error removing application:', error);
            handleSnackbarOpen('Error removing application', 'error');
        }
    };

    const handleUserDelete = async (userId) => {
        try {
            const response = await instance.post(`/users/${userId}/delete`, {}, { withCredentials: true });
            if (response.status === 200) {
                handleSnackbarOpen('User deleted successfully', 'success');
                const newUsers = users.filter((user) => user.id !== userId);
                setUsers(newUsers);
            } else {
                handleSnackbarOpen('Failed to delete user', 'error');
            }
        } catch (error) {
            console.error('Error deleting user:', error);
            handleSnackbarOpen('Error deleting user', 'error');
        }
    };

    const handleRemoveRole = async(roleId) => {
        try {
            const response = await instance.post(`/users/roles/members/remove`, { user_id: selectedUser.id, role_id: roleId }, { withCredentials: true });
            if (response.status === 200) {
                handleSnackbarOpen('Role removed successfully', 'success');
                handleEditUserRoles(selectedUser);
            } else {
                handleSnackbarOpen('Failed to remove role', 'error');
            }
        } catch (error) {
            console.error('Error removing role:', error);
            handleSnackbarOpen('Error removing role', 'error');
        }
    }

    useEffect(() => {
        const fetchAvailableReports = async () => {
            const response = await instance.post('/reports/all', {}, { withCredentials: true });
            if (response.status === 200) {
                setAvailableReports(response.data.data.reports);
            } else {
                console.error('Failed to fetch reports');
            }
        };
        fetchAvailableReports();
    }, []);

    useEffect(() => {
        const checkChangePasswordDisabled = () => {
            if (password && confirmPassword && password === confirmPassword && password.lengTableCell > 0 && confirmPassword.length > 0) {
                setChangePasswordDisabled(false);
            } else {
                setChangePasswordDisabled(true);
            }
        }
        checkChangePasswordDisabled();
    }, [password, confirmPassword]);    

    useEffect(() => {
        const fetchAllRoles = async () => {
            try {
                const response = await instance.get('/users/roles/all', { withCredentials: true });
                setAllRoles(response.data.data.roles);
            } catch (error) {
                console.error('Error fetching roles', error);
            }
        };
        fetchAllRoles();
    }, []);

    useEffect(() => {
        const fetchAllRoles = async () => {
            try {
                const response = await instance.get('/users/roles/all', { withCredentials: true });
                setAllRoles(response.data.data.roles);
            } catch (error) {
                console.error('Error fetching roles', error);
            }
        };
        fetchAllRoles();
        setRefreshUsers(false);
    }, [refreshUsers]);

    useEffect(() => {
        const fetchAvailableApplications = async () => {
            const response = await instance.post('/applications/all', {}, { withCredentials: true });
            if (response.status === 200) {
                setAvailableApplications(response.data.data.apps);
            } else {
                console.error('Failed to fetch applications');
            }
        };
        fetchAvailableApplications();
    }, []);

    const handleEditUserReports = async (user) => {
        setSelectedUser(user);
        const response = await instance.post(`/users/${user.id}/access_list`, {}, { withCredentials: true });
        if (response.status === 200) {
            setUserReports(response.data.data.access);
        } else {
            console.error('Failed to fetch user reports');
        }
        setEditUserReportsDialogOpen(true);
    };

    const handleEditUserRoles = async (user) => {
        setSelectedUser(user);
        const response = await instance.post(`/users/view/${user.id}`, {}, { withCredentials: true });
        if (response.status === 200) {
            setUserRoles(response.data.data.roles);
        } else {
            console.error('Failed to fetch user roles');
        }
        setEditUserRolesDialogOpen(true);
    };

    const handleEditUserApplications = async (user) => {
        setSelectedUser(user);
        const response = await instance.post(`/users/${user.id}/apps/access_list`, {}, { withCredentials: true });
        if (response.status === 200) {
            setUserApplications(response.data.data.access);
        } else {
            console.error('Failed to fetch user applications');
        }
        setEditUserApplicationsDialogOpen(true);
    };

    useEffect(() => {
        const checkSubmitDisabled = () => {
            if ((userName && userEmail && userPassword && userConfirmPassword) && (userPassword === userConfirmPassword)) {
                setSubmitDisabled(false);
            } else {
                setSubmitDisabled(true);
            }
        }
        checkSubmitDisabled();
    }, [userName, userEmail, userPassword, userConfirmPassword]);

    useEffect(() => {
        const fetchUsers = async () => {
            const response = await instance.post('/users/all', {}, { withCredentials: true });
            if (response.status === 200) {
                setUsers(response.data.data.users);
            } else {
                console.log('Error fetching users');
            }
        }
        fetchUsers();
        setChecked(true);
    }, [xferCRMUsers, refreshUsers]);

    const handleEditUser = async (user) => {
        const userAttributes = await instance.post(`users/view/${user.id}`, {}, { withCredentials: true });
        if (userAttributes.status === 200) {
            setUserAttributes(userAttributes.data.data);
            setId(userAttributes.data.data.id);
            setEmail(userAttributes.data.data.email);
            setIsAdmin(userAttributes.data.data.is_admin);
            setUsername(userAttributes.data.data.username);
        }
        setSelectedUser(user);
        setEditUserDialogOpen(true);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handleIsAdminChange = (e) => {
        setIsAdmin(e.target.checked);
    }

    const handleUsernameChange = (e) => {
        setUsername(e.target.value);
    }

    const handleEditUserAttributes = async (id) => {
        try {
            const response = await instance.post(`users/${id}/edit`, { email, is_admin: isAdmin, username }, { withCredentials: true });
            if (response.status === 200) {
                handleSnackbarOpen('User attributes updated successfully', 'success');
                setEditUserDialogOpen(false);
                setRefreshUsers(true);
                setUserAttributes([]);
                setId('');
                setEmail('');
                setIsAdmin(false);
                setUsername('');
                setSelectedUser(null);
            } else {
                handleSnackbarOpen('Failed to update user attributes', 'error');
            }
        } catch (error) {
            console.error('Error updating user attributes:', error);
            handleSnackbarOpen('Error updating user attributes', 'error');
        }
    }

    const handleSubmitAddUser = async () => {
        try {
            const response = await instance.post('/users/add', { username: userName, email: userEmail, password: userPassword }, { withCredentials: true });
            if (response.status === 200) {
                handleSnackbarOpen('User added successfully', 'success');
                setUserName('');
                setUserEmail('');
                setUserPassword('');
                setUserConfirmPassword('');
                setAddUser(false);
                setRefreshUsers(true);
            } else {
                handleSnackbarOpen('Failed to add user', 'error');
            }
        } catch (error) {
            console.error('Error adding user:', error);
            handleSnackbarOpen('Error adding user', 'error');
        }
    }

    return (
        <div>
            <h2 style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '10vh',
                fontFamily: "Lato"
            }}>User Management</h2>
            <Button variant='contained' onClick={() => handleToggleAddUser()}>Add User</Button>
            <Button variant='contained' onClick={() => handleXferCRMUsers()} style={{
                marginLeft: '2vh'
            }}>Transfer CRM Users</Button>
            {addUser && (
                <Dialog open={addUser} onClose={() => handleToggleAddUser()}>
                    <DialogTitle>Add User</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter user information
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin='dense'
                            id='name'
                            label='Username'
                            value={userName}
                            onChange={(e) => setUserName(e.target.value)}
                            type='text'
                            fullWidth
                            required
                        />
                        <TextField
                            margin='dense'
                            id='email'
                            label='Email'
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            type='email'
                            fullWidth
                            required
                        />
                        <TextField
                            margin='dense'
                            id='password'
                            label='Password'
                            value={userPassword}
                            onChange={(e) => setUserPassword(e.target.value)}
                            type='password'
                            fullWidth
                            required
                        />
                        <TextField
                            margin='dense'
                            id='confirm-password'
                            label='Confirm Password'
                            value={userConfirmPassword}
                            onChange={(e) => setUserConfirmPassword(e.target.value)}
                            type='password'
                            fullWidth
                            required
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => handleToggleAddUser()} color='primary'>
                            Cancel
                        </Button>
                        <Button onClick={() => handleSubmitAddUser()} color='primary' disabled={submitDisabled}>
                            Add
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
            <div>
                    <TableContainer>
                    <Fade in={checked}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>User ID</TableCell>
                                <TableCell>User Name</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Admin</TableCell>
                                <TableCell>Active</TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        {users.map((user, index) => (
                        <TableBody>
                        <TableRow key={index}>
                            <TableCell>{user.id}</TableCell>
                            <TableCell>{user.username}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell>{user.is_admin ? 'Yes' : 'No'}</TableCell>
                            <TableCell><StatusIcon isActive={user.is_active} /></TableCell>
                            <TableCell>
                            <Button onClick={() => handleEditUserReports(user)} disabled={user.is_admin}>Reports</Button>
                            </TableCell>
                            <TableCell>
                            <Button onClick={() => handleEditUserRoles(user)}>Roles</Button>
                            </TableCell>
                            <TableCell>
                            <Button onClick={() => handleEditUser(user)}>Edit User</Button>
                            </TableCell>
                            <TableCell>
                            <Button onClick={() => handleEditUserApplications(user)}>Apps</Button>
                            </TableCell>
                            <TableCell>
                            <Button color="error" onClick={() => handleUserDelete(user.id)}>Delete User</Button>
                            </TableCell>
                        </TableRow>
                        </TableBody>
                        ))}
                    </Table>
                    </Fade>
                    </TableContainer>
            </div>
            <Dialog open={editUserReportsDialogOpen} onClose={() => setEditUserReportsDialogOpen(false)}>
                <DialogTitle>{selectedUser ? `${selectedUser.username}'s Reports` : 'User Reports'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Reports accessible by TableCelle user.
                    </DialogContentText>
                    <div className='table-management'>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Report ID</TableCell>
                            <TableCell>Report Name</TableCell>
                            <TableCell>Remove</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {userReports.map((report, index) => (
                            <TableRow key={index}>
                            <TableCell>{report.id}</TableCell>
                            <TableCell>{report.name}</TableCell>
                            <TableCell>
                                <Button variant='contained' onClick={() => handleRemoveReport(report.id)}>Remove</Button>
                            </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={2}>
                            <TextField
                                select
                                label="Select Report to Add"
                                fullWidth
                                margin="dense"
                                value={selectedReportToAdd}
                                onChange={(e) => setSelectedReportToAdd(e.target.value)}
                            >
                                {availableReports.map((report) => (
                                <MenuItem key={report.id} value={report.id}>
                                    {report.name}
                                </MenuItem>
                                ))}
                            </TextField>
                            </TableCell>
                            <TableCell>
                            <Button onClick={handleAddReportToUser} color='primary' variant='contained'>
                                Add Selected Report
                            </Button>
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditUserReportsDialogOpen(false)} color='primary'>
                    Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editUserRolesDialogOpen} onClose={() => setEditUserRolesDialogOpen(false)}>
                <DialogTitle>{selectedUser ? `${selectedUser.username}'s Roles` : 'User Roles'}</DialogTitle>
                <DialogContent style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    minWidTableCell: '600px'
                }}>
                    <DialogContentText>
                    Roles assigned to TableCelle user.
                    </DialogContentText>
                    <div className='table-management'>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Role ID</TableCell>
                            <TableCell>Role Name</TableCell>
                            <TableCell>Remove</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {userRoles.map((role, index) => (
                            <TableRow key={index}>
                            <TableCell>{role.role_id}</TableCell>
                            <TableCell>{role.role_name}</TableCell>
                            <TableCell>
                                <Button variant='contained' onClick={() => handleRemoveRole(role.role_id)}>Remove</Button>
                            </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={2}>
                            <TextField
                                select
                                label="Select Role to Add"
                                fullWidth
                                margin="dense"
                                value={selectedRoleToAdd}
                                onChange={(e) => setSelectedRoleToAdd(e.target.value)}
                            >
                                {allRoles.map((role) => (
                                    <MenuItem key={role.role_id} value={role.role_id}>
                                    {role.role_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                            </TableCell>
                            <TableCell>
                            <Button onClick={() => handleAddRoleToUser()} color='primary' variant='contained'>
                                Add Selected Role
                            </Button>
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditUserRolesDialogOpen(false)} color='primary'>
                    Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editUserApplicationsDialogOpen} onClose={() => setEditUserApplicationsDialogOpen(false)}>
                <DialogTitle>{selectedUser ? `${selectedUser.username}'s Applications` : 'User Applications'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                    Applications accessible by TableCelle user.
                    </DialogContentText>
                    <div>
                    <TableContainer>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>Application ID</TableCell>
                            <TableCell>Application Name</TableCell>
                            <TableCell>Remove</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {userApplications.map((application, index) => (
                            <TableRow key={index}>
                            <TableCell>{application.id}</TableCell>
                            <TableCell>{application.name}</TableCell>
                            <TableCell>
                                <Button variant='contained' onClick={() => handleRemoveApplication(application.id)}>Remove</Button>
                            </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={2}>
                            <TextField
                                select
                                label="Select Application to Add"
                                fullWidth
                                margin="dense"
                                value={selectedApplicationToAdd}
                                onChange={(e) => setSelectedApplicationToAdd(e.target.value)}
                            >
                                {availableApplications.map((application) => (
                                <MenuItem key={application.id} value={application.id}>
                                    {application.name}
                                </MenuItem>
                                ))}
                            </TextField>
                            </TableCell>
                            <TableCell>
                            <Button onClick={handleAddApplicationToUser} color='primary' variant='contained'>
                                Add Selected Application
                            </Button>
                            </TableCell>
                        </TableRow>
                        </TableBody>
                    </Table>
                    </TableContainer>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditUserApplicationsDialogOpen(false)} color='primary'>
                    Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={xferCRMUsers} onClose={() => setXferCRMUsers(false)}>
                <DialogTitle>Transfer CRM Users</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select a CRM user to transfer to TableCelle system.
                    </DialogContentText>
                    <div>
                    <Table>
                        <TableHead>
                        <TableRow>
                            <TableCell>CRM User ID</TableCell>
                            <TableCell>CRM User Name</TableCell>
                            <TableCell>Transfer</TableCell>
                        </TableRow>
                        </TableHead>
                        <TableBody>
                        {crmUsers.map((crmUser, index) => (
                            <TableRow key={index}>
                            <TableCell>{crmUser.id}</TableCell>
                            <TableCell>{crmUser.name}</TableCell>
                            <TableCell>
                                <Button variant='contained' onClick={() => handleTransferCRMUser(crmUser.id)}>Transfer</Button>
                            </TableCell>
                            </TableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setXferCRMUsers(false)} color='primary'>
                    Cancel
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editUserDialogOpen} onClose={() => setEditUserDialogOpen(false)} maxWidTableCell='lg'>
                <DialogTitle>{selectedUser ? `${selectedUser.username}'s Attributes` : 'User Attributes'}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        User attributes.
                    </DialogContentText>
                    <div>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>Id</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Is Admin</TableCell>
                                <TableCell>Username</TableCell>
                                <TableCell></TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell>{id}</TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        value={email}
                                        onChange={handleEmailChange}
                                        sx={{
                                            widTableCell: '15rem'
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Checkbox
                                        checked={isAdmin}
                                        onChange={handleIsAdminChange}
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="text"
                                        value={username}
                                        onChange={handleUsernameChange}
                                        sx={{
                                            widTableCell: '10rem'
                                        }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Button variant='contained' onClick={() => handleSetChangePassword()}>Change Password</Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditUserDialogOpen(false)} color='primary'>
                        Close
                    </Button>
                    <Button onClick={() => handleEditUserAttributes(selectedUser.id)} color='primary'>
                        Update
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={changePasswordBox} onClose={() => setChangePasswordBox(false)}>
                <DialogTitle>Change Password</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter new password.
                    </DialogContentText>
                    <TextField
                        type='password'
                        label='New Password'
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        fullWidth
                        margin='dense'
                    />
                    <TextField
                        type='password'
                        label='Confirm New Password'
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        fullWidth
                        margin='dense'
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setChangePasswordBox(false)} color='primary'>
                        Cancel
                    </Button>
                    <Button onClick={() => handleChangePassword(id)} color='primary' disabled={changePasswordDisabled}>
                        Change
                    </Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ widTableCell: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default UserManagement;
