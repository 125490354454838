import { useState } from 'react';
import DashboardManagement from './DashboardManagement';
import AppManagement from './AppManagement';
import RoleManagement from './RoleManagement';
import DrillThrough from './DrillThrough';
import ReportManagement from './ReportManagement';
import UserManagement from './UserManagement';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const labelStyle = {
    fontFamily: 'Shadows Into Light',
    fontSize: '1.5rem',
    fontWeight: 'bold',
    fontStyle: 'normal'
}

const headerStyle = {
                textAlign: 'center',
                marginBottom: '2vh',
                fontFamily: "Lato"
            }

function Admin() {
    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%' }}>
            <h1 style={headerStyle}>Admin Tasks</h1>
            <Tabs
                value={tabValue}
                onChange={handleTabChange}
                aria-label="Admin tasks tabs"
                centered  
                variant="fullWidth"  
                indicatorColor="primary" 
                textColor="primary"
                sx={{ marginBottom: '2vh' }} 
            >
                <Tab style={labelStyle} label="Users"/>
                <Tab style={labelStyle} label="Reports"/>
                <Tab style={labelStyle} label="DrillThroughs"/>
                <Tab style={labelStyle} label="Dashboards"/>
                <Tab style={labelStyle} label="Roles"/>
                <Tab style={labelStyle} label="Applications"/>
            </Tabs>
        {tabValue === 0 && <UserManagement />}
        {tabValue === 1 && <ReportManagement />}
        {tabValue === 2 && <DrillThrough />}
        {tabValue === 3 && <DashboardManagement />}
        {tabValue === 4 && <RoleManagement />}
        {tabValue === 5 && <AppManagement />}
        </Box>
    );
}

export default Admin;
