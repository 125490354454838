import { createContext, useState, useEffect } from 'react';
import instance from '../axios/axios';
import useAuth from '../hooks/useAuth';

const GlobalContext = createContext();

export const GlobalProvider = ({ children }) => {
    const [navItems, setNavItems] = useState([]);
    const [apps, setApps] = useState([]);
    const [anchorElReports, setAnchorElReports] = useState(null);
    const [anchorElApps, setAnchorElApps] = useState(null);
    const [anchorElDashboards, setAnchorElDashboards] = useState(null);
    const [dashboards, setDashboards] = useState([]);
    const { auth } = useAuth();

    useEffect(() => {
        if (auth?.auth) {
            instance.get('/users/access_list_from_jwt', { withCredentials: true })
                .then((response) => {
                    if (response.status === 200) {
                        const accessList = response.data.data.access;
                        setNavItems(accessList);
                    } else {
                        console.error('Error getting access list');
                    }
                })
                .catch((error) => {
                    console.error('Error getting access list', error);
                });
        }
    }, [anchorElReports]);

    useEffect(() => {
        if (auth?.auth) {
            instance.get('/users/dashboards/access_list_from_jwt', { withCredentials: true })
                .then((response) => {
                    if (response.status === 200) {
                        const accessList = response.data.data.access;
                        setDashboards(accessList);
                    } else {
                        console.error('Error getting access list');
                    }
                })
                .catch((error) => {
                    console.error('Error getting access list', error);
                });
        }
    }, [anchorElReports]);


    useEffect(() => {
        const fetchApps = async () => {
            try {
                const response = await instance.get('/users/apps/access_list_from_jwt', { withCredentials: true });
                if (response.status === 200) {
                    setApps(response.data.data.access);
                }
            } catch (error) {
                console.error('Error getting applications', error);
            }
        };
        if (auth?.auth) {
            fetchApps();
        }
    }, [anchorElApps]);

    return (
        <GlobalContext.Provider
            value={{
                navItems,
                setNavItems,
                apps,
                setApps,
                anchorElReports,
                setAnchorElReports,
                anchorElApps,
                setAnchorElApps,
                dashboards,
                setDashboards,
                anchorElDashboards,
                setAnchorElDashboards 
            }}
        >
            {children}
        </GlobalContext.Provider>
    );
}

export default GlobalContext;
