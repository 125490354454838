import { useEffect, useState } from 'react';
import Fade from '@mui/material/Fade';
import instance from '../axios/axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Snackbar, Alert } from '@mui/material';

function DrillThrough() {
    const [drillThroughs, setDrillThroughs] = useState([]);
    const [addDrillthrough, setAddDrillthrough] = useState(false);
    const [allReports, setAllReports] = useState([]);
    const [selectedParentReport, setSelectedParentReport] = useState(null);
    const [selectedChildReport, setSelectedChildReport] = useState(null);
    const [parentColumnWithLink, setParentColumnWithLink] = useState('');
    const [linkedParams, setLinkedParams] = useState([{ parent_value: '', child_key: ''}]);
    const [parentHeaders, setParentHeaders] = useState([]);
    const [childRequiredParams, setChildRequiredParams] = useState([]);
    const [refresh, setRefresh] = useState(true);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [check, setCheck] = useState(false);

useEffect(() => {
    const fetchDrillThroughs = async () => {
        const response = await instance.get('/reports/drillthrus/all', { withCredentials: true });
        if (response.status === 200) {
            setDrillThroughs(response.data.data.drillthrus);
            setCheck(true);
        }
    };

    fetchDrillThroughs();
    
    if (refresh) {
        setRefresh(false); 
    }
}, [refresh]);

    useEffect(() => {
        const fetchReports = async () => {
            const response = await instance.post('/reports/all', {}, { withCredentials: true });
            if (response.status === 200) {
                setAllReports(response.data.data.reports);
            }
        };
        fetchReports();
    }, [addDrillthrough]);

    useEffect(() => {
        const fetchChildRequiredParams = async () => {
            if (selectedChildReport === null) {
                return;
            }
            try {
                const response = await instance.get(`/reports/view/${selectedChildReport}`, { withCredentials: true });
                if (response.status === 200) {
                    setChildRequiredParams(response.data.data.required_params);
                } else {
                    console.error('Failed to fetch child required params:', response);
                }
            } catch (error) {
                console.error('Failed to fetch child required params:', error);
            }
        }
        fetchChildRequiredParams();
    }, [selectedChildReport]);

    useEffect(() => {
        if (selectedParentReport === null) {
            return;
        }
        const fetchParentHeaders = async () => {
            try {
                const response = await instance.get(`/reports/${selectedParentReport}/keys`, { withCredentials: true });
                if (response.status === 200) {
                setParentHeaders(response.data.keys); 
                } 
                else if (response.status === 404) {
                    const secondaryResponse = await instance.get(`reports/test/${selectedParentReport}`, { withCredentials: true });
                    if (secondaryResponse.status === 200) {
                        setParentHeaders(secondaryResponse.data.data.keys);
                    } else {
                        console.error('Failed to fetch parent headers:', secondaryResponse);
                    }
                } else {
                    console.error('Failed to fetch parent headers:', response);
                }
            } catch (error) {
                console.error('Failed to fetch parent headers:', error);
            }
        }
        fetchParentHeaders();
    }, [selectedParentReport]);

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleRemoveDrillThrough = async (drill_id) => {
        try {
            const response = await instance.post(`/reports/drillthrus/${drill_id}/delete`, {}, { withCredentials: true });
            if (response.status === 200) {
                setDrillThroughs(drillThroughs.filter((drillThrough) => drillThrough.drill_thru_id !== drill_id));
            } else {
                console.error('Failed to remove drillthrough:', response);
                handleSnackbarOpen('Failed to remove drillthrough', 'error');
            }
        } catch (error) {
            console.error('Failed to remove drillthrough:', error);
            handleSnackbarOpen('Failed to remove drillthrough', 'error');
        }
    }

    const handleAddDrillthrough = async () => {
        try {
            const response = await instance.post('/reports/drillthrus/add', {
                parent_report_id: selectedParentReport,
                child_report_id: selectedChildReport,
                parent_column_with_link: parentColumnWithLink,
                linked_params: linkedParams
            }, { withCredentials: true });
            if (response.status === 200) {
                setAddDrillthrough(false);
                setRefresh(true);
                handleSnackbarOpen('Drillthrough added successfully', 'success');
            } else {
                console.error('Failed to add drillthrough:', response);
                handleSnackbarOpen('Failed to add drillthrough', 'error');
            }
        } catch (error) {
            console.error('Failed to add drillthrough:', error);
            handleSnackbarOpen('Failed to add drillthrough', 'error');
        }
    }

    const handleParamChange = (index, key, value) => {
        const newLinkedParams = [...linkedParams];
        newLinkedParams[index][key] = value;
        setLinkedParams(newLinkedParams);
    }

    const addLinkedParam = () => {
        setLinkedParams([...linkedParams, { parent_value: '', child_key: '' }]);
    }

    return (
        <div>
            <h2 style={{
                textAlign: 'center',
                marginBottom: '2vh',
                paddingTop: '2vh',
                fontFamily: "Lato"
            }}>DrillThrough Management</h2>
            <div>
            <Button variant='contained' color='primary' onClick={() => setAddDrillthrough(true)}>Add DrillThrough</Button>
            <Fade in={check}>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>DrillThrough ID</TableCell>
                        <TableCell>Parent Report</TableCell>
                        <TableCell>Child Report</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {drillThroughs.map((drillThrough) => (
                        <TableRow key={drillThrough.drill_thru_id}>
                            <TableCell>{drillThrough.drill_thru_id}</TableCell>
                            <TableCell>{drillThrough.parent_report.report_name}</TableCell>
                            <TableCell>{drillThrough.child_report.report_name}</TableCell>
                            <TableCell>
                                <Button color='error' onClick={() => handleRemoveDrillThrough(drillThrough.drill_thru_id)}>Remove</Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            </Fade>
            </div>
            <Dialog open={addDrillthrough} onClose={() => setAddDrillthrough(false)}>
                <DialogTitle>Add DrillThrough</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Add a new DrillThrough relationship between two reports.
                    </DialogContentText>
                    <TextField
                        select
                        label='Parent Report'
                        fullWidth
                        value={selectedParentReport}
                        onChange={(event) => setSelectedParentReport(event.target.value)}
                        sx={{ marginBottom: 2 }}
                    >
                        {allReports.map((report) => (
                            <MenuItem key={report.id} value={report.id}>
                                {report.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        select
                        label='Child Report'
                        fullWidth
                        value={selectedChildReport}
                        onChange={(event) => setSelectedChildReport(event.target.value)}
                        sx={{ marginBottom: 2 }}
                    >
                        {allReports.map((report) => (
                            <MenuItem key={report.id} value={report.id}>
                                {report.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                    select
                    label='Parent Column with Link'
                    fullWidth
                    value={parentColumnWithLink}
                    onChange={(event) => setParentColumnWithLink(event.target.value)}
                    sx={{ marginBottom: 2 }}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                maxHeight: 100,  // Set the maximum height for the dropdown
                                overflow: 'auto'  // Enable scrolling
                            }
                        }
                    }}
                    >
                    {parentHeaders.map((header, index) => (
                        <MenuItem key={index} value={header} style={{
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'block',
                            width: '100%',
                        }}>
                            {header}
                        </MenuItem>
                    ))}
                    </TextField>
                    {linkedParams.map((param, index) => (
                        <div key={index} style={{ marginBottom: '1rem' }}>
                            <TextField
                                select
                                label={`Parent Value ${index + 1}`}
                                fullWidth
                                value={param.parent_value}
                                onChange={(event) => handleParamChange(index, 'parent_value', event.target.value)}
                                sx={{ marginBottom: 2 }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 100,  // Set the maximum height for the dropdown
                                            overflow: 'auto'  // Enable scrolling
                                        }
                                    }
                                }}
                            >
                                {parentHeaders.map((header, index) => (
                                    <MenuItem key={index} value={header}>
                                        {header}
                                    </MenuItem>
                                ))}
                            </TextField>
                            <TextField
                                select
                                label={`Child Key ${index + 1}`}
                                fullWidth
                                value={param.child_key}
                                onChange={(event) => handleParamChange(index, 'child_key', event.target.value)}
                                sx={{ marginBottom: 2 }}
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 100,  // Set the maximum height for the dropdown
                                            overflow: 'auto'  // Enable scrolling
                                        }
                                    }
                                }}
                            >
                                {childRequiredParams.map((param, index) => (
                                    <MenuItem key={index} value={param.field_name}>
                                        {param.field_name}
                                    </MenuItem>
                                ))}
                            </TextField>
                        </div>
                    ))}
                    <Button variant='contained' onClick={addLinkedParam}>
                        Add Another Parameter Mapping
                    </Button>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setAddDrillthrough(false)}>Cancel</Button>
                    <Button onClick={() => handleAddDrillthrough()}>Add</Button>
                </DialogActions>
            </Dialog>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default DrillThrough;
