import React, { useState, useEffect } from 'react';
import instance from '../axios/axios';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Button, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, MenuItem, Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function RoleManagement() {
    const [allRoles, setAllRoles] = useState([]);
    const [addRole, setAddRole] = useState(false);
    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [roleName, setRoleName] = useState('');
    const [roleDescription, setRoleDescription] = useState('');
    const [editRole, setEditRole] = useState(false);
    const [allRoleReports, setAllRoleReports] = useState([]);
    const [selectedRole, setSelectedRole] = useState(null);
    const [allReports, setAllReports] = useState([]);
    const [editRoleReports, setEditRoleReports] = useState(false);
    const [selectedReportToAdd, setSelectedReportToAdd] = useState(null);
    const [refresh, setRefresh] = useState(false);
    const [addDashboard, setAddDashboard] = useState(false);
    const [allDashboards, setAllDashboards] = useState([]);
    const [allRoleDashboards, setAllRoleDashboards] = useState([]);
    const [editRoleDashboards, setEditRoleDashboards] = useState(false);
    const [selectedDashboardToAdd, setSelectedDashboardToAdd] = useState(null);
    const [allApplications, setAllApplications] = useState([]);
    const [allRoleApplications, setAllRoleApplications] = useState([]);
    const [editRoleApplications, setEditRoleApplications] = useState(false);
    const [selectedApplicationToAdd, setSelectedApplicationToAdd] = useState(null);

    const handleCloseSnackbar = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    useEffect(() => {
        const fetchReports = async () => {
            try {
                const response = await instance.post('/reports/all', {}, { withCredentials: true });
                setAllReports(response.data.data.reports);
            } catch (error) {
                console.error('Error fetching reports', error);
            }
        };
        fetchReports();
    }, [editRoleReports]);

    useEffect(() => {
        const fetchDashboards = async () => {
            try {
                const response = await instance.post('/reports/dashboards/all', {}, { withCredentials: true });
                setAllDashboards(response.data.data.dashboards);
            } catch (error) {
                console.error('Error fetching dashboards', error);
            }
        };
        fetchDashboards();
    }, [editRoleDashboards]);

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                const response = await instance.post('/applications/all', {}, { withCredentials: true });
                setAllApplications(response.data.data.apps);
            } catch (error) {
                console.error('Error fetching applications', error);
            }
        };
        fetchApplications();
    }, [editRoleApplications]);

    useEffect(() => {
        const fetchRoles = async () => {
            try {
                const response = await instance.get('/users/roles/all', { withCredentials: true });
                setAllRoles(response.data.data.roles);
            } catch (error) {
                console.error('Error fetching roles', error);
            }
        };
        fetchRoles();
        if (refresh) {
            setRefresh(false);
        }
    }, [editRole, refresh]);

    const handleAddReportToRole = async () => {
        if (!selectedReportToAdd || !selectedRole) {
            console.error('No report or role selected');
            setSnackbarMessage('No report or role selected');
            setSnackbarSeverity('error');
            setOpen(true);
            return;
        }

        try {
            const response = await instance.post(`/access/roles/add`, { report_id: selectedReportToAdd, role_id: selectedRole.role_id }, { withCredentials: true });
            if (response.status === 200) {
                const addedReport = allReports.find(report => report.id === selectedReportToAdd);
                if (addedReport) {
                    setAllRoleReports(prev => [...prev, { report_id: addedReport.id, report_name: addedReport.name }]);
                    setSnackbarMessage('Report successfully added to role');
                    setSnackbarSeverity('success');
                    setOpen(true);
                }
            } else {
                throw new Error('Failed to add report to role');
            }
        } catch (error) {
            console.error('Error adding report to role', error);
            setSnackbarMessage('Error adding report to role');
            setSnackbarSeverity('error');
            setOpen(true);
        }
    };

    const handleRemoveReportFromRole = async (report_id) => {
        try {
            const response = await instance.post(`/access/roles/delete`, { report_id: report_id, role_id: selectedRole.role_id }, { withCredentials: true });
            if (response.status === 200) {
                const newRoleReports = allRoleReports.filter(report => report.report_id !== report_id);
                setAllRoleReports(newRoleReports);
                setSnackbarMessage('Report successfully removed from role');
                setSnackbarSeverity('success');
                setOpen(true);
            } else {
                throw new Error('Failed to remove report from role');
            }
        } catch (error) {
            console.error('Error removing report from role', error);
            setSnackbarMessage('Error removing report from role');
            setSnackbarSeverity('error');
            setOpen(true);
        }
    };

    const handleAddDashboardToRole = async () => {
        if (!selectedDashboardToAdd || !selectedRole) {
            console.error('No dashboard or role selected');
            setSnackbarMessage('No dashboard or role selected');
            setSnackbarSeverity('error');
            setOpen(true);
            return;
        }

        try {
            const response = await instance.post(`/access/roles/add_dashboard`, { dashboard_id: selectedDashboardToAdd, role_id: selectedRole.role_id }, { withCredentials: true });
            if (response.status === 200) {
                const addedDashboard = allDashboards.find(dashboard => dashboard.id === selectedDashboardToAdd);
                if (addedDashboard) {
                    setAllRoleDashboards(prev => [...prev, { dashboard_id: addedDashboard.id, dashboard_name: addedDashboard.name }]);
                    setSnackbarMessage('Dashboard successfully added to role');
                    setSnackbarSeverity('success');
                    setOpen(true);
                }
            } else {
                throw new Error('Failed to add dashboard to role');
            }
        } catch (error) {
            console.error('Error adding dashboard to role', error);
            setSnackbarMessage('Error adding dashboard to role');
            setSnackbarSeverity('error');
            setOpen(true);
        }
    };

    const handleRemoveDashboardFromRole = async (dashboard_id) => {
        try {
            const response = await instance.post(`/access/roles/delete_dashboard`, { dashboard_id: dashboard_id, role_id: selectedRole.role_id }, { withCredentials: true });
            if (response.status === 200) {
                const newRoleDashboards = allRoleDashboards.filter(dashboard => dashboard.dashboard_id !== dashboard_id);
                setAllRoleDashboards(newRoleDashboards);
                setSnackbarMessage('Dashboard successfully removed from role');
                setSnackbarSeverity('success');
                setOpen(true);
            } else {
                throw new Error('Failed to remove dashboard from role');
            }
        } catch (error) {
            console.error('Error removing dashboard from role', error);
            setSnackbarMessage('Error removing dashboard from role');
            setSnackbarSeverity('error');
            setOpen(true);
        }
    };

    const handleAddApplicationToRole = async () => {
        if (!selectedApplicationToAdd || !selectedRole) {
            console.error('No application or role selected');
            setSnackbarMessage('No application or role selected');
            setSnackbarSeverity('error');
            setOpen(true);
            return;
        }

        try {
            const response = await instance.post(`/access/apps/add_role`, { application_id: selectedApplicationToAdd, role_id: selectedRole.role_id }, { withCredentials: true });
            if (response.status === 200) {
                const addedApplication = allApplications.find(application => application.id === selectedApplicationToAdd);
                if (addedApplication) {
                    setAllRoleApplications(prev => [...prev, { application_id: addedApplication.id, application_name: addedApplication.name }]);
                    setSnackbarMessage('Application successfully added to role');
                    setSnackbarSeverity('success');
                    setOpen(true);
                }
            } else {
                throw new Error('Failed to add application to role');
            }
        } catch (error) {
            console.error('Error adding application to role', error);
            setSnackbarMessage('Error adding application to role');
            setSnackbarSeverity('error');
            setOpen(true);
        }
    };

    const handleRemoveApplicationFromRole = async (application_id) => {
        try {
            const response = await instance.post(`/access/apps/delete_role`, { application_id: application_id, role_id: selectedRole.role_id }, { withCredentials: true });
            if (response.status === 200) {
                const newRoleApplications = allRoleApplications.filter(application => application.application_id !== application_id);
                setAllRoleApplications(newRoleApplications);
                setSnackbarMessage('Application successfully removed from role');
                setSnackbarSeverity('success');
                setOpen(true);
            } else {
                throw new Error('Failed to remove application from role');
            }
        } catch (error) {
            console.error('Error removing application from role', error);
            setSnackbarMessage('Error removing application from role');
            setSnackbarSeverity('error');
            setOpen(true);
        }
    };

    const handleAddRole = async () => {
        try {
            const response = await instance.post('/users/roles/add', { name: roleName, description: roleDescription }, { withCredentials: true });
            if (response.status === 200) {
                setEditRole(true);
                setSnackbarMessage('Role successfully added');
                setSnackbarSeverity('success');
                setOpen(true);
                setAddRole(false);
                setRefresh(true);
            } else {
                throw new Error('Failed to add role');
            }
        } catch (error) {
            console.error('Error adding role', error);
            setSnackbarMessage('Error adding role');
            setSnackbarSeverity('error');
            setOpen(true);
        }
    };

    const handleEditRole = (role_id) => {
        console.log('Edit role with id:', role_id);
    };

    const handleDeleteRole = async (role_id) => {
        try {
            const response = await instance.post(`/users/roles/${role_id}/delete`, {}, { withCredentials: true });
            if (response.status === 200) {
                const newRoles = allRoles.filter((role) => role.role_id !== role_id);
                setAllRoles(newRoles);
                setSnackbarMessage('Role successfully deleted');
                setSnackbarSeverity('success');
                setOpen(true);
            } else {
                throw new Error('Failed to delete role');
            }
        } catch (error) {
            console.error('Error deleting role:', error);
            setSnackbarMessage('Error deleting role');
            setSnackbarSeverity('error');
            setOpen(true);
        }
    };

    const handleEditRoleReports = (role) => {
        setSelectedRole(role);
        setEditRoleReports(true);
        const fetchRoleReports = async () => {
            try {
                const response = await instance.get(`/users/roles/view/${role.role_id}`, { withCredentials: true });
                if (response.status === 200) {
                    setAllRoleReports(response.data.data.report_access);
                } else {
                    throw new Error('Failed to fetch role reports');
                }
            } catch (error) {
                console.error('Error fetching role reports', error);
                setSnackbarMessage('Error fetching role reports');
                setSnackbarSeverity('error');
                setOpen(true);
            }
        };
        fetchRoleReports();
    };

    const handleEditRoleDashboards = (role) => {
        setSelectedRole(role);
        setEditRoleDashboards(true);
        const fetchRoleDashboards = async () => {
            try {
                const response = await instance.get(`/users/roles/view/${role.role_id}`, { withCredentials: true });
                if (response.status === 200) {
                    setAllRoleDashboards(response.data.data.dashboard_access);
                } else {
                    throw new Error('Failed to fetch role dashboards');
                }
            } catch (error) {
                console.error('Error fetching role dashboards', error);
                setSnackbarMessage('Error fetching role dashboards');
                setSnackbarSeverity('error');
                setOpen(true);
            }
        };
        fetchRoleDashboards();
    };

    const handleEditRoleApplications = (role) => {
        setSelectedRole(role);
        setEditRoleApplications(true);
        const fetchRoleApplications = async () => {
            try {
                const response = await instance.get(`/users/roles/view/${role.role_id}`, { withCredentials: true });
                if (response.status === 200) {
                    setAllRoleApplications(response.data.data.application_access);
                } else {
                    throw new Error('Failed to fetch role applications');
                }
            } catch (error) {
                console.error('Error fetching role applications', error);
                setSnackbarMessage('Error fetching role applications');
                setSnackbarSeverity('error');
                setOpen(true);
            }
        };
        fetchRoleApplications();
    };

    return (
        <div>
            <h2 style={{ fontFamily: "Lato", textAlign: 'center' }}>Role Management</h2>
            <Snackbar open={open} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                <Alert onClose={handleCloseSnackbar} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Button variant='contained' color="primary" onClick={() => setAddRole(!addRole)}>Add Role</Button>
            {addRole && (
                <Dialog open={addRole} onClose={() => setAddRole(false)}>
                    <DialogTitle>Add Role</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            Enter the details of the role you would like to add.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="role_name"
                            value={roleName}
                            onChange={(e) => setRoleName(e.target.value)}
                            label="Role Name"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            margin="dense"
                            id="role_description"
                            value={roleDescription}
                            onChange={(e) => setRoleDescription(e.target.value)}
                            label="Role Description"
                            type="text"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setAddRole(false)}>Cancel</Button>
                        <Button onClick={() => handleAddRole()}>Add</Button>
                    </DialogActions>
                </Dialog>
            )}
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Role ID</TableCell>
                        <TableCell>Role Name</TableCell>
                        <TableCell>Role Description</TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {allRoles.map((role) => {
                        return (
                            <TableRow key={role.role_id}>
                                <TableCell>{role.role_id}</TableCell>
                                <TableCell>{role.role_name}</TableCell>
                                <TableCell>{role.role_description}</TableCell>
                                <TableCell>
                                    <Button color="primary" onClick={() => handleEditRole(role.role_id)}>Edit</Button>
                                </TableCell>
                                <TableCell>
                                    <Button color="info" onClick={() => handleEditRoleReports(role)}>Edit Reports</Button>
                                </TableCell>
                                <TableCell>
                                    <Button color="warning" onClick={() => handleEditRoleDashboards(role)}>Edit Dashboards</Button>
                                </TableCell>
                                <TableCell>
                                    <Button color="secondary" onClick={() => handleEditRoleApplications(role)}>Edit Applications</Button>
                                </TableCell>
                                <TableCell>
                                    <Button color="error" onClick={() => handleDeleteRole(role.role_id)}>Delete</Button>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                </TableBody>
            </Table>
            <Dialog open={editRoleReports} onClose={() => setEditRoleReports(false)}>
                <DialogTitle>Edit Role Reports</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the reports that this role should have access to.
                    </DialogContentText>
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Report ID</TableCell>
                                    <TableCell>Report Name</TableCell>
                                    <TableCell>Remove</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allRoleReports.map((report) => (
                                    <TableRow key={report.report_id}>
                                        <TableCell>{report.report_id}</TableCell>
                                        <TableCell>{report.report_name}</TableCell>
                                        <TableCell>
                                            <Button color="error" onClick={() => handleRemoveReportFromRole(report.report_id)}>Remove</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <TextField
                                            select
                                            label="Select Report to Add"
                                            fullWidth
                                            margin="dense"
                                            value={selectedReportToAdd}
                                            onChange={(e) => setSelectedReportToAdd(e.target.value)}
                                        >
                                            {allReports.filter(report => !allRoleReports.map(r => r.report_id).includes(report.id)).map((report) => (
                                                <MenuItem key={report.id} value={report.id}>
                                                    {report.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleAddReportToRole()} color='primary' variant='contained'>
                                            Add Selected Report
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditRoleReports(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editRoleDashboards} onClose={() => setEditRoleDashboards(false)}>
                <DialogTitle>Edit Role Dashboards</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the dashboards that this role should have access to.
                    </DialogContentText>
                    <div className='table-management'>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Dashboard ID</TableCell>
                                    <TableCell>Dashboard Name</TableCell>
                                    <TableCell>Remove</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allRoleDashboards.map((dashboard) => (
                                    <TableRow key={dashboard.dashboard_id}>
                                        <TableCell>{dashboard.dashboard_id}</TableCell>
                                        <TableCell>{dashboard.dashboard_name}</TableCell>
                                        <TableCell>
                                            <Button color="error" onClick={() => handleRemoveDashboardFromRole(dashboard.dashboard_id)}>Remove</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <TextField
                                            select
                                            label="Select Dashboard to Add"
                                            fullWidth
                                            margin="dense"
                                            value={selectedDashboardToAdd}
                                            onChange={(e) => setSelectedDashboardToAdd(e.target.value)}
                                        >
                                            {allDashboards.filter(dashboard => !allRoleDashboards.map(d => d.dashboard_id).includes(dashboard.id)).map((dashboard) => (
                                                <MenuItem key={dashboard.id} value={dashboard.id}>
                                                    {dashboard.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleAddDashboardToRole()} color='primary' variant='contained'>
                                            Add Selected Dashboard
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditRoleDashboards(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={editRoleApplications} onClose={() => setEditRoleApplications(false)}>
                <DialogTitle>Edit Role Applications</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Select the applications that this role should have access to.
                    </DialogContentText>
                    <div>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Application ID</TableCell>
                                    <TableCell>Application Name</TableCell>
                                    <TableCell>Remove</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {allRoleApplications.map((application) => (
                                    <TableRow key={application.application_id}>
                                        <TableCell>{application.application_id}</TableCell>
                                        <TableCell>{application.application_name}</TableCell>
                                        <TableCell>
                                            <Button color="error" onClick={() => handleRemoveApplicationFromRole(application.application_id)}>Remove</Button>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={2}>
                                        <TextField
                                            select
                                            label="Select Application to Add"
                                            fullWidth
                                            margin="dense"
                                            value={selectedApplicationToAdd}
                                            onChange={(e) => setSelectedApplicationToAdd(e.target.value)}
                                        >
                                            {allApplications.filter(application => !allRoleApplications.map(a => a.application_id).includes(application.id)).map((application) => (
                                                <MenuItem key={application.id} value={application.id}>
                                                    {application.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                    </TableCell>
                                    <TableCell>
                                        <Button onClick={() => handleAddApplicationToRole()} color='primary' variant='contained'>
                                            Add Selected Application
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setEditRoleApplications(false)}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default RoleManagement;
