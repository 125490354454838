import React from 'react';
import Dashboard from './components/Dashboard';
import DynamicApplicationComponent from './components/RenderApplication';
import ProtectedRoute from './components/ProtectedRoute';
import Admin from './components/Admin';
import ReactDOM from 'react-dom/client';
import Notfound from './components/Notfound'
import NewHome from './components/HomePage';
import { createBrowserRouter, RouterProvider, Route } from 'react-router-dom';
import { AuthProvider } from './context/AuthProvider';
import { GlobalProvider } from './context/GlobalProvider';
import CustomThemeProvider from './context/CustomThemeProvider';
import { ThemeToggleProvider } from './context/ThemeContext';
import { ReportProvider } from './context/ReportProvider';
import Reporting from './components/Reporting';
import CssBaseline from '@mui/material/CssBaseline';
import darkTheme from './theme';
import App from './App';
import './index.css';

const router = createBrowserRouter([
    {
        path: '/',
        element: <App />,
        children: [
            {
                path: '/',
                element: <NewHome />
            },
            {
                path: '/report/:reportId',
                element: <ReportProvider><Reporting /></ReportProvider>
            },
            {
                path: '/admin',
                element: <ProtectedRoute><Admin /></ProtectedRoute>
           },
            {
                path: '/app/:applicationId',
                element: <DynamicApplicationComponent />
            }, 
            {
                path: '/dashboard',
                element: <Dashboard />
            },
            {
                path: '*',
                element: <Notfound />
            }
        ]
    }]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ThemeToggleProvider>
    <CustomThemeProvider>
        <CssBaseline />
    <AuthProvider>
        <GlobalProvider>
            <RouterProvider router={router} />
        </GlobalProvider>
    </AuthProvider>
    </CustomThemeProvider>
    </ThemeToggleProvider>
  </React.StrictMode>
);

