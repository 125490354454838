import React, { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import instance from '../axios/axios';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import StatBoxWrapper from './StatboxWrapper';
import CircularProgress from '@mui/material/CircularProgress';
import LineChartComponent from "./LineChartComponent";
import MyLineChart from "./ApexLineChartComponent";
import '../css/Dashboard.css'

const oneWeekAgo = new Date();
oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);
const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

const loaderStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh'
};


const Dashboard = () => {
    const [dashboards, setDashboards] = useState([]);
    const [startDate, setStartDate] = useState(formatDate(oneWeekAgo));
    const [endDate, setEndDate] = useState(formatDate(new Date()));
    const [lineChartData, setLineChartData] = useState([]);
    const [selectDate, setSelectDate] = useState([]);
    const [pieChartData, setPieChartData] = useState([]);
    const [statBoxData, setStatBoxData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [noDashboards, setNoDashboards] = useState(false);
    const sectionRef = useRef(null);
    const [dateSwitcher, setDateSwitcher] = useState(false);

    const location = useLocation();

    useEffect(() => {
        switch (selectDate) {
            case '1':
                setStartDate(formatDate(new Date()));
                setEndDate(formatDate(new Date()));
                break;
            case '2':
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                setStartDate(formatDate(yesterday));
                setEndDate(formatDate(yesterday));
                break;
            case '3':
                const thisWeek = new Date();
                const thisWeekMonday = thisWeek.getDate() - thisWeek.getDay() + 1;
                const thisWeekSunday = thisWeekMonday + 6;
                setStartDate(formatDate(new Date(thisWeek.setDate(thisWeekMonday))));
                setEndDate(formatDate(new Date(thisWeek.setDate(thisWeekSunday))));
                break;
            case '4':
                const lastWeek = new Date();
                const lastWeekMonday = lastWeek.getDate() - lastWeek.getDay() - 6;
                const lastWeekSunday = lastWeekMonday + 6;
                setStartDate(formatDate(new Date(lastWeek.setDate(lastWeekMonday))));
                setEndDate(formatDate(new Date(lastWeek.setDate(lastWeekSunday))));
                break;
            case '5':
                const thisMonth = new Date();
                const firstDay = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), 1);
                const lastDay = new Date(thisMonth.getFullYear(), thisMonth.getMonth() + 1, 0);
                setStartDate(formatDate(firstDay));
                setEndDate(formatDate(lastDay));
                break;
            case '6':
                const lastMonth = new Date();
                const lastMonthFirstDay = new Date(lastMonth.getFullYear(), lastMonth.getMonth() - 1, 1);
                const lastMonthLastDay = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 0);
                setStartDate(formatDate(lastMonthFirstDay));
                setEndDate(formatDate(lastMonthLastDay));
                break;
            default:
                break;
        }
        setDateSwitcher(true);
    }, [selectDate]);

    useEffect(() => {
        const fetchDashboards = async () => {
            setLoading(true);
            setNoDashboards(false);
            try {
                const result = await instance.get('users/dashboards/access_list_from_jwt', { withCredentials: true });
                if (result.status === 200 && result.data.data.access.length > 0) {
                    setDashboards(result.data.data.access);
                    setNoDashboards(false);
                } else {
                    setNoDashboards(true);
                }
            } catch (error) {
                console.log('Error fetching data:', error);
                setNoDashboards(true);
            }
            setLoading(false);
        };

        fetchDashboards();
        setDateSwitcher(false);
    }, [location, dateSwitcher]);

    useEffect(() => {
        const adjustFlexBasis = () => {
            const nodes = sectionRef.current.querySelectorAll('.chart');
            const count = nodes.length;
            const basis = count === 1 ? '100%' : `calc(${100 / Math.min(3, count)}% - 20px)`;
            nodes.forEach(node => node.style.flex = `1 1 ${basis}`);
        };

        if (dashboards.length) {
            adjustFlexBasis();
        }
    }, [dashboards]);

    useEffect(() => {
        const fetchDashboardData = async () => {
            if (dashboards.length > 0) {
                setLoading(true);
                const dashboardDataPromises = dashboards.map(async (dashboard) => {
                    try {
                        const result = await instance.post(`reports/dashboards/${dashboard.id}`, { start_date: startDate, end_date: endDate }, { withCredentials: true });
                        if (result.status === 200) {
                            return { ...result.data, chart_type: dashboard.chart_type };
                        }
                    } catch (error) {
                        console.log('Error fetching data:', error);
                    }
                    return null;
                });

                const resolvedDashboardData = await Promise.all(dashboardDataPromises);
                const filteredData = resolvedDashboardData.filter(data => data !== null);

                // Process and categorize data
                const lineData = [];
                const pieData = [];
                const statData = [];

                filteredData.forEach((data) => {
                    if (data.chart_type === 'line') {
                        lineData.push(data);
                    } else if (data.chart_type === 'pie') {
                        pieData.push(data);
                    } else if (data.chart_type === 'stats') {
                        statData.push(data);
                    }
                });
                setLineChartData(lineData.map(item => item[0].response));
                setPieChartData(pieData.map(item => item[0].response));
                setStatBoxData(statData.map(item => item[0].response));

                setLoading(false); // Set loading to false once data is loaded
            }
        };

        fetchDashboardData();
    }, [dashboards]);

    if (loading) {
        return (
            <div style={loaderStyle}>
                <CircularProgress size={80} />
            </div>
        );
    }

    if (noDashboards && !loading) {
        return (
            <div className="no-dashboards">
                <h1 style={{ textAlign: "center" }}>No Dashboards Available</h1>
            </div>
        );
    }

    return (
        <div className="dashboard-container">
            <div className="date-select">
                <FormControl sx={{ minWidth: "20vw" }}>
                    <InputLabel id="date-select-label">Select Date Range</InputLabel>
                    <Select
                        labelId="date-select-label"
                        id="date-select"
                        value={selectDate}
                        label="Select Date Range"
                        onChange={(e) => setSelectDate(e.target.value)}
                    >   
                        <MenuItem value="1">Today</MenuItem>
                        <MenuItem value="2">Yesterday</MenuItem>
                        <MenuItem value="3">This Week</MenuItem> 
                        <MenuItem value="4">Last Week</MenuItem> 
                        <MenuItem value="5">This Month</MenuItem> 
                        <MenuItem value="6">Last Month</MenuItem> 
                    </Select>
                </FormControl>
            </div>
            <div ref={sectionRef} className="section">
                {statBoxData.map((data, index) => (
                    <div key={index} className="statbox">
                        <h2 style={{ textAlign: "center" }}>{data.name}</h2>
                        <StatBoxWrapper data={data.data} />
                    </div>
                ))}
            </div>
            <div className="section">
                {lineChartData.map((data, index) => (
                    <div key={index} className="chart">
                        <h2 style={{ textAlign: "center" }}>{data.name}</h2>
                        <MyLineChart data={data}
                        />
                    </div>
                ))}
            </div>
            <div className="section">
                {pieChartData.map((data, index) => (
                    <div key={index} className="chart">
                        <h2 style={{ textAlign: "center" }}>{data.name}</h2>
                        <PieChart
                            series={[data]}
                            width={500}
                            height={300}
                        />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Dashboard;
