import React, { useState } from 'react';
import instance from '../axios/axios';
import useAuth from '../hooks/useAuth';
import Snackbar, { SnackbarCloseReason } from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import logo from '../assets/medigaplogotransparent2.svg';
import CircularProgress from '@mui/material/CircularProgress';
import { TextField, Button } from '@mui/material';
import testImage from '../assets/idksquirreltransparent.png';

const logoStyle = {
    textAlign: "center",
    height: "25vh"
}

function Login({ onLogin }) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [loginError, setLoginError] = useState("");
    const [open, setOpen] = useState(false);
    const { setAuth } = useAuth();


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        try {
            setLoginError(null)
            setIsLoading(true);
            const response = await instance.post('/login', { username, password }, { withCredentials: true });
            if (response.status === 200) {
                setAuth({auth: true, user: response.data.user_store.user_id, is_admin: response.data.user_store.is_admin});
            }
        }
        catch (error) {
            if (error.response.status === 401) {
                alert('Invalid username or password');
                setLoginError("Not really sure what happened there..definitely let us know that this occurred.");
                setSnackbarSeverity('error');
                setOpen(true)
            } else if (error.response.status === 418) {
                setLoginError("Username or Email doesn't exist");
                setSnackbarSeverity('error');
                setOpen(true)
            } else if (error.response.status === 406) {
                setLoginError("Invalid Password");
                setSnackbarSeverity('error');
                setOpen(true)
            }
        } finally {
            setIsLoading(false);
        }
    }

    return (
        <div>
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '10px',
            width: '200px',
            margin: 'auto',
            marginTop: '100px',
        }}>
        <Snackbar open={open} autoHideDuration={6000} onClose={() => setOpen(false)}>
            <Alert onClose={() => setOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                <div style={{ display: "flex", flexDirection: "column" }}>
               <img src={testImage} style={{  maxHeight: "20vh", paddingRight: "1vwa", flex: "1" }}/>
                <p style={{ flex: "1", textAlign: "center" }}>{loginError}</p>
            </div>
            </Alert>
        </Snackbar>
        <img src={logo} style={logoStyle} alt="Medigap Vision Logo" />
            <TextField
            label="Username" 
            placeholder="Username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            />
            <TextField
            label="Password"
            placeholder="Password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            />
            <Button
            variant="contained"
            onClick={handleFormSubmit}
            >
            Login
            </Button>
        </div>
        {isLoading && (
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <CircularProgress />
            </div>
        )}
        </div>
    )
}

export default Login;
