function Footer() {
  return (
<footer style={{
    minHeight: '15vh',
}}>
  <div style={{width: '100%', textAlign: 'center'}}>
    <div style={{minHeight: '10vh'}}></div>
    <p className="footer__text">© 2024 Medigap Vision</p>
  </div>
</footer>
  );
}

export default Footer;
