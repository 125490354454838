import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import nope from '../assets/nope-finger-wag.gif';

const StyledContainer = styled(Container)(({ theme }) => ({
    display: 'flex',
    fontFamily: 'Shadows Into Light',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '50vh',
    textAlign: 'center',
}));

const Notfound = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <StyledContainer>
      <Typography sx={{ fontSize: '4rem', marginBottom: '1rem', fontFamily: "Shadows Into Light" }} variant="h1">
        404
      </Typography>
      <Typography sx={{ fontSize: '1.5rem', marginBottom: '2rem', fontFamily: "Shadows Into Light" }} variant="h5">
        Page Not Found
      </Typography>
      <Typography variant="body1">
        The page you are trying to access does not exist.
      </Typography>
      <img src={nope} alt="Nope" />
      <Box mt={3}>
        <Button
          sx={{ fontSize: '1rem' }}
          variant="contained"
          onClick={handleGoBack}
        >
          Back Home
        </Button>
      </Box>
    </StyledContainer>
  );
};

export default Notfound;

