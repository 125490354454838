import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import nope from '../assets/nope-finger-wag.gif';

const StyledContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  height: '50vh',
  textAlign: 'center',
}));

const Unauthorized = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate('/');
  };

  return (
    <StyledContainer>
      <Typography sx={{ fontSize: '4rem', marginBottom: '1rem' }} variant="h1">
        401
      </Typography>
      <Typography sx={{ fontSize: '1.5rem', marginBottom: '2rem' }} variant="h5">
        Unauthorized Access
      </Typography>
      <Typography variant="body1">
        You do not have the necessary permissions to view this page.
      </Typography>
      <img src={nope} alt="Nope" />
      <Box mt={3}>
        <Button
          sx={{ fontSize: '1rem' }}
          variant="contained"
          onClick={handleGoBack}
        >
          Back Home
        </Button>
      </Box>
    </StyledContainer>
  );
};

export default Unauthorized;
