import React, { useEffect, useState } from 'react';
import Fade from '@mui/material/Fade'; 
import {
    Button, Grid2, TextField, Snackbar, Alert, Dialog, DialogActions, DialogContent,
    DialogContentText, DialogTitle
} from '@mui/material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import instance from '../axios/axios';
import SmartToyIcon from '@mui/icons-material/SmartToy';

const containerStyle = {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    padding: '20px',
                    gap: '20px',
                    width: '100%'
                }

function AppManagement() {
    const [addApplication, setAddApplication] = useState(false);
    const [applicationName, setApplicationName] = useState('');
    const [applicationPath, setApplicationPath] = useState('');
    const [applicationComponentPath, setApplicationComponentPath] = useState('');
    const [applicationApiKey, setApplicationApiKey] = useState('');
    const [allApplications, setAllApplications] = useState([]);
    const [refresh, setRefresh] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('info');
    const [openAddApplication, setOpenAddApplication] = useState(false);
    const [check, setChecked] = useState(false);

    const handleClick = () => {
        setOpenAddApplication(!openAddApplication);
    };

    useEffect(() => {
        const fetchApplications = async () => {
            try {
                const response = await instance.post('/applications/all', {}, { withCredentials: true });
                if (response.status === 200) {
                    setAllApplications(response.data.data.apps);
                    setChecked(true);
                } else {
                    handleSnackbarOpen('Error fetching applications', 'error');
                }
            } catch (error) {
                console.error('Fetch error:', error);
                handleSnackbarOpen('Error fetching applications', 'error');
            }
            if (refresh) {
                setRefresh(false);
            }
        };
        fetchApplications();
    }, [addApplication, refresh]);

    const createPayload = () => ({
        name: applicationName,
        base_url: applicationPath,
        api_key: applicationApiKey,
        component_path: applicationComponentPath,
    });

    const handleSnackbarOpen = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleDeleteApplication = async (index) => {
        const response = await instance.post(`/applications/${allApplications[index].id}/delete`, {}, { withCredentials: true });
        if (response.status === 200) {
            const updatedApplications = allApplications.filter((application, i) => i !== index);
            setAllApplications(updatedApplications);
            handleSnackbarOpen('Application deleted successfully', 'success');
        } else {
            handleSnackbarOpen('Error deleting application', 'error');
        }
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();
        const payload = createPayload();
        try {
            const response = await instance.post('/applications/add', payload, { withCredentials: true });
            if (response.status === 200) {
                handleSnackbarOpen('Application added successfully', 'success');
                setOpenAddApplication(false);
                setApplicationName('');
                setApplicationPath('');
                setRefresh(true);
            } else {
                handleSnackbarOpen('Error adding application', 'error');
            }
        } catch (error) {
            console.error('Submission error:', error);
            handleSnackbarOpen('Error adding application', 'error');
        }
    };

    return (
        <Grid2 container justifyContent="center" alignItems="center">
            <Grid2 item xs={12} md={6} lg={4}>
                <div style={containerStyle}>
                    <h2 style={{
                        fontFamily: "Lato"
                    }}>Application Management</h2>
                    <Button color="primary" onClick={handleClick} style={{ alignSelf: 'flex-end' }}>
                        {openAddApplication ? 'Close' : 'Add Application'}
                    </Button>
                    <Dialog open={openAddApplication} onClose={() => setOpenAddApplication(false)}>
                        <DialogTitle>Add a New Application</DialogTitle>
                        <form onSubmit={handleFormSubmit}>
                            <DialogContent>
                                <DialogContentText>
                                    Please fill out the form below to add a new application.
                                </DialogContentText>
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Application Name"
                                    variant="outlined"
                                    value={applicationName}
                                    onChange={(e) => setApplicationName(e.target.value)}
                                    helperText="Enter a unique name for the application."
                                    required
                                    margin="dense"
                                />
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Application Base URL"
                                    variant="outlined"
                                    value={applicationPath}
                                    onChange={(e) => setApplicationPath(e.target.value)}
                                    helperText="Enter the path to the application."
                                    required
                                    margin="dense"
                                />
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Application API Key"
                                    variant="outlined"
                                    value={applicationApiKey}
                                    onChange={(e) => setApplicationApiKey(e.target.value)}
                                    helperText="Enter the API keys for the application."
                                    required
                                    margin="dense"
                                />
                                <TextField
                                    fullWidth
                                    type="text"
                                    label="Application Component Path"
                                    variant="outlined"
                                    value={applicationComponentPath}
                                    onChange={(e) => setApplicationComponentPath(e.target.value)}
                                    helperText="Enter the path to the application component."
                                    required
                                    margin="dense"
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => setOpenAddApplication(false)} color="primary">
                                    Cancel
                                </Button>
                                <Button type="submit" color="primary">
                                    Add Application
                                </Button>
                            </DialogActions>
                        </form>
                    </Dialog>
                    <Fade in={check}>
                    <div className='application-management'>
                        {allApplications.length > 0 && addApplication === false && (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell> </TableCell>
                                        <TableCell>#</TableCell>
                                        <TableCell>Name</TableCell>
                                        <TableCell>Base URL</TableCell>
                                        <TableCell>Component</TableCell>
                                        <TableCell> </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {allApplications.map((application, index) => (
                                        <TableRow key={index}>
                                            <TableCell><SmartToyIcon /></TableCell>
                                            <TableCell>{index + 1}</TableCell>
                                            <TableCell>{application.name}</TableCell>
                                            <TableCell>{application.base_url}</TableCell>
                                            <TableCell>{application.component_path}</TableCell>
                                            <TableCell>
                                                <Button color="error" onClick={() => handleDeleteApplication(index)}>
                                                    Delete
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        )}
                    </div>
                    </Fade>
                </div>
            </Grid2>
            <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={() => setSnackbarOpen(false)}>
                <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity} sx={{ width: '100%' }}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Grid2>
    );
}

export default AppManagement;
