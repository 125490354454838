import React, { useEffect, useState } from 'react';
import instance from '../axios/axios';
import CircularProgress from '@mui/material/CircularProgress';
import { useParams } from 'react-router-dom';
 
const DynamicApplicationComponent = () => {
  const [Component, setComponent] = useState(null);

  let { applicationId } = useParams();
 
  useEffect(() => {
    async function fetchApplication() {
      const response = await instance.post(`/applications/view/${applicationId}`, {}, {  withCredentials: true });
      const importedComponent = await import(`../applications/${response.data.data.component_path}`);
      setComponent(() => importedComponent.default);
    }
 
    fetchApplication();
  }, []);
 
  if (!Component) {
    return <div><CircularProgress /></div>;
  }
 
  return <Component />;
};
 
export default DynamicApplicationComponent;
