import { Link } from 'react-router-dom';
import GlobalContext from '../context/GlobalProvider';
import ThemeContext from '../context/ThemeContext';
import { Menu, MenuItem, IconButton } from '@mui/material';
import { useContext, useEffect } from 'react';
import instance from '../axios/axios';
import DarkModeIcon from '@mui/icons-material/DarkMode';
import LightModeIcon from '@mui/icons-material/LightMode';
import useAuth from '../hooks/useAuth';
import logo from '../assets/medigaplogotransparent2.svg';
import Button from '@mui/material/Button';

const linkStyle = {
    fontFamily: 'Overpass Mono',
    color: 'white',
    fontWeight: '400',
    textDecoration: 'none',
    marginLeft: '20px',
    marginRight: '20px',
}

const adminLinkStyle = {
    color: 'white',
    fontFamily: 'Shadows Into Light',
    fontSize: '1.5rem',
    fontWeight: '400',
    fontStyle: 'normal',
    textDecoration: 'none',
    marginLeft: '20px',
    marginRight: '20px',
}

const lightAdminLinkStyle = {
    color: 'black',
    fontWeight: '400',    
    fontFamily: 'Shadows Into Light',
    fontSize: '1.5rem',
    fontStyle: 'normal',
    textDecoration: 'none',
    marginLeft: '20px',
    marginRight: '20px',
}

const lightLinkStyle = {
    fontFamily: 'Overpass Mono',
    color: 'black',
    textDecoration: 'none',
    marginLeft: '20px', 
    marginRight: '20px',
}

const menuSlotProps = {
    paper: {
        style: {
            maxHeight: 90 * 4.5,
            width: 'auto',
        }
    }
}

const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}

const menuStyle = {
    marginTop: '2vh',  // Adjust the distance between the menu and the button
}

const logoutStyle = {
    paddingRight: "5vw",  // Add padding for spacing from the right edge
    minWidth: "auto",
    width: "fit-content",
    padding: "8px 16px",
    whiteSpace: 'nowrap',
}

const linkContainerStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}

const divStyle = {            
    display: 'flex',
    justifyContent: 'space-between',  // Adjusts the layout to create space between elements
    alignItems: 'center',
    paddingTop: "2vh",
    paddingLeft: "5vw",
    paddingRight: "5vw"
}

const logoStyle = {
    height: "13vh",
    width: "auto",
}


function Nav () {
    const { auth, setAuth } = useAuth();
    const { navItems, setNavItems, 
            setAnchorElReports, setAnchorElApps, 
            anchorElApps, anchorElReports, apps, setApps,
            dashboards, setDashboards} = useContext(GlobalContext);
    const { isDarkTheme, toggleTheme } = useContext(ThemeContext);

    const testingInterval = async () => {
        const fetchReports = async () => {
            try {
                const reportResponse = await instance.get('/users/access_list_from_jwt', { withCredentials: true });
                if (reportResponse.status === 200) {
                    setNavItems(reportResponse.data.data.access);
                } else {
                    console.error("Failed to get reports");
                }
            } catch (error) {
                console.error(`Error fetching reports: ${error}`);
            }
        }

        const fetchApps = async () => {
            try {
                const appResponse = await instance.get('/users/apps/access_list_from_jwt', { withCredentials: true });
                if (appResponse.status === 200) {
                    setApps(appResponse.data.data.access);
                } else {
                    console.error("Failed to get apps")
                }
            } catch (error) {
                console.error(`Error fetching applications: ${error}`)
            }
        }

        const fetchDashboards = async () => {
            try {
                const dashboardResponse = await instance.get('/users/dashboards/access_list_from_jwt', { withCredentials: true });
                if (dashboardResponse.status === 200) {
                    setDashboards(dashboardResponse.data.data.access);
                } else {
                    console.error("Failed getting dashboards");
                }
            } catch (error) {
                console.error(`Error getting dashboards: ${error}`);
            }
        }

        await Promise.all([fetchApps(), fetchReports(), fetchDashboards()])    
    };

        //await instance.get('/users/access_list_from_jwt', { withCredentials: true })
        //    .then((response) => {
        //        if (response.status === 200) {
        //            console.log('fetched reports')
        //            const accessList = response.data.data.access;
        //            setNavItems(accessList);
        //            console.log(navItems);
        //        } else {
        //            console.log('Error getting access list');
        //        }
        //    })
        //    .catch((error) => {
        //        console.log('Error getting access list', error);
        //    });

        //await instance.get('/users/apps/access_list_from_jwt', { withCredentials: true })
        //    .then((response) => {
        //        if (response.status === 200) {
        //            console.log('fetched apps')
        //            const accessList = response.data.data.access;
        //            setApps(accessList);
        //        } else {
        //            console.log('Error getting access list');
        //        }
        //    })
        //    .catch((error) => {
        //        console.log('Error getting access list', error);
        //    });

        //await instance.get('/users/dashboards/access_list_from_jwt', { withCredentials: true })
        //    .then((response) => {
        //        if (response.status === 200) {
        //            console.log(`${response.data.data.access}`);
        //            setDashboards(response.data.data.access);
        //        } else {
        //            console.log("Error getting dashboards");
        //        }
        //    })
        //    .catch((error) => {
        //        console.log(`Error getting access list: ${error}`);
        //    });
    

    useEffect(() => {
        testingInterval();
        const interval = setInterval(testingInterval, 120000);
        return () => clearInterval(interval);
    }, []);

    const openReports = Boolean(anchorElReports);
    const openApps = Boolean(anchorElApps);

    const handleReportsClick = (event) => {
        setAnchorElReports(event.currentTarget);
        setAnchorElApps(null); // Close the other menu
    };

    const handleAppsClick = (event) => {
        setAnchorElApps(event.currentTarget);
        setAnchorElReports(null); // Close the other menu
    };

    const handleClose = () => {
        setAnchorElReports(null);
        setAnchorElApps(null);
    };

    const logout = async (e) => {
        e.preventDefault();
        try {
            const response = await instance.post('/logout', {}, { withCredentials: true });
            if (response.status === 200) {
                setAuth({});
            } else {
                alert('Error logging out');
            }
        } catch (error) {
            alert('Error logging out');
        }
    };


    return (
        <div style={divStyle}>
            <Link to="/"><img src={logo} style={logoStyle} alt="Medigap Vision Logo" /></Link>
                <div style={linkContainerStyle}>
            {dashboards.length > 0 && (
            <Link to="/dashboard" style={isDarkTheme ? adminLinkStyle : lightAdminLinkStyle}>Dashboards</Link>
            )}
                {navItems.length > 0 && (
                <IconButton
                onClick={handleReportsClick}
                aria-controls="reports-menu"
                aria-haspopup="true"
                aria-label="Reports"
                sx={isDarkTheme ? adminLinkStyle : lightAdminLinkStyle}
                >
                Reports
                </IconButton>
                )}
                {apps.length > 0 && (
                <IconButton
                onClick={handleAppsClick}
                aria-controls="apps-menu"
                aria-haspopup="true"
                aria-label="Apps"
                sx={isDarkTheme ? adminLinkStyle : lightAdminLinkStyle}
                >
                Apps
                </IconButton>
                )}
                {auth.is_admin && (<Link to="/admin" style={isDarkTheme ? adminLinkStyle : lightAdminLinkStyle}>Admin</Link>)}
                <Menu
                id="reports-menu"
                anchorEl={anchorElReports}
                open={openReports}
                onClose={handleClose}
                style={menuStyle}
                slotProps={menuSlotProps}
                >
                {navItems.map((item) => (
                    <MenuItem key={item.id} onClick={handleClose}>
                    <Link to={`/report/${item.id}`} style={isDarkTheme ? linkStyle : lightLinkStyle}>{item.name}</Link>
                    </MenuItem>
                ))}
                </Menu>
                <Menu
                id="apps-menu"
                anchorEl={anchorElApps}
                open={openApps}
                onClose={handleClose}
                style={menuStyle}
                >
                {apps.map((item) => (
                    <MenuItem key={item.id} onClick={handleClose}>
                    <Link to={`/app/${item.id}`} style={isDarkTheme ? linkStyle : lightLinkStyle}>{item.name}</Link>
                    </MenuItem>
                ))}
                </Menu>
            </div>
            <div style={buttonContainerStyle}>
            <Button
            onClick={logout}
            sx={logoutStyle}
            >
            Logout
            </Button>
            <Button
            onClick={() => toggleTheme()}
            sx={logoutStyle}
            >
            {isDarkTheme ? <LightModeIcon /> : <DarkModeIcon />}
            </Button>
            </div>
        </div>
    )
};

export default Nav;
