import React from 'react';
import '../css/StatBox.css'

const StatBox = ({ title, value }) => {
    return (
        <div className="stat-box">
            <h3>{title}</h3>
            <h2>{value}</h2>
        </div>
    );
}

export default StatBox;
