import { createContext, useState, useEffect } from 'react';
import instance from '../axios/axios';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const ReportContext = createContext();

export const ReportProvider = ({ children }) => {
    const auth = useAuth();
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');

    return (
        <ReportContext.Provider
            value={{
                startDate,
                setStartDate,
                endDate,
                setEndDate,
            }}
        >
            {children}
        </ReportContext.Provider>
    );
}

export default ReportContext;

