import { useEffect, useState, useContext } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Slide from '@mui/material/Slide';
import ReportContext from '../context/ReportProvider';
import '../css/override.css';
import DynamicTable from './TheTable';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import instance from '../axios/axios';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import NoDataFound from './NoDataFound';
import HTTPError from './HTTPError';

const circularProgressStyle = { display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }
const stackStyle = {
            display: 'flex', 
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '1vh',
            marginBottom: '1vh',
            }
const headerStyle = {fontFamily: "Lato", textAlign: "center"};

const progressDiv = { margin: '5vh', padding: '2vh', overflowX: 'auto' }

const formatDate = (date) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
};

function Reporting() {
    const [data, setData] = useState([]);
    const [columns, setColumns] = useState([]);
    const [reqParamaters, setReqParameters] = useState([]);
    const [page_name, setPageName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [hasChildren, setHasChildren] = useState(false);
    const [childReports, setChildReports] = useState([]);
    const [loadFromParams, setLoadFromParams] = useState(false);
    const location = useLocation();
    const [parentReport, setParentReport] = useState(null);
    const [displayBackButton, setDisplayBackButton] = useState(false);
    const [filteredData, setFilteredData] = useState([]);
    const [filterText, setFilterText] = useState('');
    const [checked, setChecked] = useState(false);
    const [error, setError] = useState(null);
    const [madeReq, setMadeReq] = useState(false);
    const [selectDate, setSelectDate] = useState(0);

    const searchParams = new URLSearchParams(location.search);
    const { startDate, endDate, setStartDate, setEndDate } = useContext(ReportContext);

    let { reportId } = useParams();
    const navigate = useNavigate();

    const goBackToParentReport = async () => {
        const result = await instance.get(`/reports/view/${parentReport.parent_report_id}`, { withCredentials: true });
        if (result.status !== 200) {
            console.error("Error fetching parent report:", result);
        } else {
            if (result.data.data.parent_reports.length > 0) {
                navigate(-1);
                setDisplayBackButton(false)
            } else {
                const parentId = parentReport?.parent_report_id;
                const parentUrl = `/report/${parentId}?start_date=${startDate}&end_date=${endDate}`;
                navigate(parentUrl);
                setDisplayBackButton(false);
            }
        }
    };

    useEffect(() => {
        if (!reqParamaters.length) return; 

        let newStartDate, newEndDate;

        switch (selectDate) {
            case '1': // Today
                newStartDate = formatDate(new Date());
                newEndDate = formatDate(new Date());
                break;
            case '2': // Yesterday
                const yesterday = new Date();
                yesterday.setDate(yesterday.getDate() - 1);
                newStartDate = formatDate(yesterday);
                newEndDate = formatDate(yesterday);
                break;
            case '3': // This Week (Monday to Sunday)
                const thisWeek = new Date();
                const thisWeekMonday = thisWeek.getDate() - thisWeek.getDay() + 1;
                const thisWeekSunday = thisWeekMonday + 6;
                newStartDate = formatDate(new Date(thisWeek.setDate(thisWeekMonday)));
                newEndDate = formatDate(new Date(thisWeek.setDate(thisWeekSunday)));
                break;
            case '4': // Last Week (Monday to Sunday)
                const lastWeek = new Date();
                const lastWeekMonday = lastWeek.getDate() - lastWeek.getDay() - 6;
                const lastWeekSunday = lastWeekMonday + 6;
                newStartDate = formatDate(new Date(lastWeek.setDate(lastWeekMonday)));
                newEndDate = formatDate(new Date(lastWeek.setDate(lastWeekSunday)));
                break;
            case '5': // This Month
                const thisMonth = new Date();
                const firstDay = new Date(thisMonth.getFullYear(), thisMonth.getMonth(), 1);
                const lastDay = new Date(thisMonth.getFullYear(), thisMonth.getMonth() + 1, 0);
                newStartDate = formatDate(firstDay);
                newEndDate = formatDate(lastDay);
                break;
            case '6': // Last Month
                const lastMonth = new Date();
                const lastMonthFirstDay = new Date(lastMonth.getFullYear(), lastMonth.getMonth() - 1, 1);
                const lastMonthLastDay = new Date(lastMonth.getFullYear(), lastMonth.getMonth(), 0);
                newStartDate = formatDate(lastMonthFirstDay);
                newEndDate = formatDate(lastMonthLastDay);
                break;
            default:
                break;
        }

        setReqParameters((prevParams) => {
            return prevParams.map((param) => {
                if (param.field_name === 'start_date') {
                    return { ...param, value: newStartDate };
                }
                if (param.field_name === 'end_date') {
                    return { ...param, value: newEndDate };
                }
                return param;
            });
        });

        //handleSubmit(); 

    }, [selectDate]);



    const handleFilterChange = (event) => {
        const value = event.target.value;
        setFilterText(value);
        const lowercasedValue = value.toLowerCase();
        const filtered = data.filter(item =>
            Object.keys(item).some(key =>
                item[key].toString().toLowerCase().includes(lowercasedValue)
            )
        );
        setFilteredData(filtered);
    };

    useEffect(() => {
        setData([]);
        setColumns([]);
        setReqParameters([]);
        setStartDate('');
        setEndDate('');
        setIsLoading(false);
        const fetchChildReports = async () => {
            try {
                const response = await instance.get(`/reports/view/${reportId}`, { withCredentials: true });
                if (response.status === 200) {
                    if (response.data.data.child_reports.length > 0) {
                        setHasChildren(true);
                        setChildReports(response.data.data.child_reports);
                    } else {
                        setHasChildren(false);
                    }
                    if (response.data.data.parent_reports.length > 0) {
                        setDisplayBackButton(true);
                        setParentReport(response.data.data.parent_reports[0]);
                    }
                    setChecked(true);
                } else {
                    setError(`Error fetching child reports - ${response.status}: ${response.statusText}`);
                    console.error("Error fetching child reports:", response);
                }
            } catch (err) {
                setError(`Error fetching child reports - ${err.response.request.status}: ${err.code}` || "Error fetching child reports.")
                console.error("Error fetching child reports:", err);
            }
        }
        fetchChildReports();
    }, [reportId]);

    useEffect(() => {
        if (!filterText) {
            setFilteredData(data); // Reset if filter is cleared
        }
    }, [filterText, data]);

    useEffect(() => {
        // Update the reqParameters with values from the URL
        const newParams = [];
        searchParams.forEach((value, key) => {
            newParams.push({ field_name: key, value: value });
            // Also update startDate and endDate if they are part of the URL search params
            if (key === 'start_date') {
                setStartDate(value);
            } else if (key === 'end_date') {
                setEndDate(value);
            }
        });
        setReqParameters(newParams);

        // Define what should happen after the URL search params have been updated
        const updateData = async () => {
            setIsLoading(true);
            setLoadFromParams(true);
            setMadeReq(false);
            setError(null);
            try {
                let relevantData = undefined;
                const reportingURL = `/reports/${reportId}`;
                const payload = preparePayload(newParams);
                const response = await instance.post(reportingURL, payload, { withCredentials: true });
                if (response.status === 200) {
                    setIsLoading(false);
                    const responseData = response.data;

                    if (responseData && responseData.length > 0) {
                        relevantData = responseData[0];
                        setData(relevantData);
                    }

                    if (relevantData.length > 0) {
                        const firstItemKeys = Object.keys(relevantData[0]);
                        const newColumns = firstItemKeys.map(key => ({
                            Header: key.replace(/([A-Z])/g, '$1').trim(),
                            accessor: key,
                        }));
                        setColumns(newColumns);
                    }
                } else {
                    setError(`Error fetching data - ${response.status}: ${response.statusText}`);
                    console.error("Error fetching data:", response);
                }
            } catch (err) {
                setError(`Error fetching data - ${err.response.request.status}: ${err.code}` || "Error fetching data.");
                console.error("Error fetching report parameters:", error);
            } finally {
                setIsLoading(false);
            }
        };

        // Call the updateData function if the URL search params have changed
        if (newParams.length > 0) {
            updateData();
        }
    }, [location.search]);

    useEffect(() => {
        const fetchData = async () => {
            setMadeReq(false);
            setError(null);
            try {
                setDisplayBackButton(false);
                if (reportId) {
                    const response = await instance.get(`/reports/view/${reportId}`, { withCredentials: true });
                    if (response.status === 200) {
                        let requiredParams = response.data.data.required_params.filter(param => !param.field_type.startsWith('$'));

                        // Check URL parameters and prefill if available
                        requiredParams = requiredParams.map(param => {
                            const urlValue = searchParams.get(param.field_name);
                            return { ...param, value: urlValue ? urlValue : '' };
                        });

                        setReqParameters(requiredParams);
                        setPageName(response.data.data.name);

                        if (!location.search && !loadFromParams) {
                            setData([]);
                            setColumns([]);
                        }

                    } else {
                        setError(`Error fetching report parameters, please contact your closest engineer - ${response.status}: ${response.statusText}.`)
                    }
                }
            } catch (err) {
                setError(`Error fetching report parameters, please contact your closest engineer - ${err.response.request.status}: ${err.code}.`)
                console.error("Error fetching report parameters:", error);            }
        };

        fetchData();
    }, [reportId, location.search]);


    function convertToCSV(data) {
        const csvRows = [];
        const headers = Object.keys(data[0]);
        csvRows.push(headers.join(','));

        for (const row of data) {
            const values = headers.map(header => {
                const escaped = ('' + row[header]).replace(/"/g, '\\"'); 
                return `"${escaped}"`;
            });
            csvRows.push(values.join(','));
        }
        return csvRows.join('\n');
    }

    function downloadCSV(data) {
        const csvString = convertToCSV(data);
        const blob = new Blob([csvString], { type: 'text/csv;charset=utf-8;' });
        const link = document.createElement('a');
        const url = URL.createObjectURL(blob);
        link.setAttribute('href', url);
        link.setAttribute('download', 'data.csv');
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    const preparePayload = (parameters) => {
        const payload = {};
        parameters.forEach(param => {
            if (param.value !== undefined) {
                payload[param.field_name] = param.value;
            }
        });
        return payload;
    };

    const handleInputChange = (index, newValue) => {
        console.log(newValue);
        setReqParameters(currentReqParameters =>
            currentReqParameters.map((param, i) =>
                i === index ? { ...param, value: newValue } : param
            )
        );
        console.log(reqParamaters);
    };

    const handleSubmit = async (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();  
        }
        setData([]);
        setColumns([]);
        setIsLoading(true);
        setMadeReq(false);
        setError(null);
        const startDateParam = reqParamaters.find(param => param.field_name === 'start_date');
        const endDateParam = reqParamaters.find(param => param.field_name === 'end_date');

        if (startDateParam && endDateParam) {
            setStartDate(startDateParam.value);
            setEndDate(endDateParam.value);
        }

        try {
            let relevantData = undefined;
            const reportingURL = `/reports/${reportId}`;
            const payload = preparePayload(reqParamaters);
            const response = await instance.post(reportingURL, payload,
                { withCredentials: true });
            setMadeReq(true);
            if (response.status === 200) {
                setIsLoading(false);
                const responseData = response.data;
                if (responseData && responseData.length > 0) {
                    relevantData = responseData[0];

                    setData(relevantData);
                }
                if (relevantData !== undefined && relevantData.length > 0) {
                    const firstItemKeys = Object.keys(relevantData[0]);
                    const newColumns = firstItemKeys.map(key => ({
                        Header: key.replace(/([A-Z])/g, '$1').trim(),
                        accessor: key,
                    }));
                    setColumns(newColumns);
                }
            } else {
                setIsLoading(false);
                setError(`Error fetching data - ${response.status}: ${response.statusText}`);
            }
        } catch (err) {
            setIsLoading(false);
            setMadeReq(true);
            console.error("Error fetching data:", err);
            if (err.response && err.response.status === 401) {
                setError(`Invalid date range`);
                alert('Invalid date range');
            } else if (err.response && err.response.status !== 401) {
                setError(`Error fetching data - ${err.response.request.status}: ${err.code}` || "Error fetching data.");
            }
        }
    }
    const handleDrillThrough = (childReportId, linkedParams) => {
        const urlParams = new URLSearchParams();
        linkedParams.forEach(param => {
            const parentValue = data.find(row => row[param.parent_value]);
            if (parentValue) {
                urlParams.append(param.child_key, parentValue[param.parent_value]);
            }
        })
        const drillThroughUrl = `/report/${childReportId}?${urlParams.toString()}`;
        navigate(drillThroughUrl);
    }

  return (
    <div>
        <form onSubmit={handleSubmit}>
          <div className='header'>
            <h1 className='page-title' style={headerStyle}>{page_name}</h1>
          </div>
          <div>
            <Stack spacing={1} direction="row" style={stackStyle}>
            {reqParamaters.find(param => param.field_name === 'start_date') && (
              <FormControl sx={{ minWidth: "10vw", paddingRight: "2vw" }}>
                  <InputLabel id="date-select-label">Select Date Range</InputLabel>
                  <Select
                  labelId="date-select-label"
                  id="date-select"
                  value={selectDate}
                  label="Select Date Range"
                  onChange={(e) => setSelectDate(e.target.value)}
                  >   
                      <MenuItem value="1">Today</MenuItem>
                      <MenuItem value="2">Yesterday</MenuItem>
                      <MenuItem value="3">This Week</MenuItem> 
                      <MenuItem value="4">Last Week</MenuItem> 
                      <MenuItem value="5">This Month</MenuItem> 
                      <MenuItem value="6">Last Month</MenuItem> 
                  </Select>
              </FormControl>
            )}
            {
              reqParamaters && reqParamaters.map((param, index) => (
                <Slide in={checked} direction="right" key={index}>
                <Input key={index} 
                      type={param.field_type}
                      placeholder={param.field_name} 
                      name={param.field_name} 
                      label={param.field_name}
                      value={param.value || ''}
                      onChange={(e) => handleInputChange(index, e.target.value)}
                    />
                </Slide>
              ))
            }
                <Button type="submit">Submit</Button>
                <Button onClick={() => downloadCSV(data)}>
                  Download CSV
                </Button>
                {displayBackButton && (
                  <Button onClick={goBackToParentReport}>
                    Back to {parentReport?.parent_report_name}
                  </Button>
                )}
                  <Input
                    placeholder="Filter..."
                    value={filterText}
                    onChange={handleFilterChange}
                  />
            </Stack>
          </div>
        </form>
        <div style={progressDiv}>
        {isLoading && (
          <div style={circularProgressStyle}>
            <CircularProgress />
          </div>
        )}
      {!isLoading && error && !madeReq && <HTTPError message="Internal server error" errorDetail={error} onRetry={handleSubmit} />}
      {!isLoading && error && madeReq && <HTTPError message="Error fetching data" errorDetail={error} onRetry={handleSubmit} />}
      {!isLoading && !error && madeReq && (data.length === 0 || columns.length === 0) && <NoDataFound/>}
      {!isLoading && columns.length > 0 && data.length > 0 && (
          <DynamicTable 
            data={filteredData} 
            columns={columns}
            hasChildren={hasChildren}
            childReports={childReports}
            startDate={startDate}
            endDate={endDate}
            onDrillThrough={handleDrillThrough} // Pass the drill-through handler to the DynamicTable element
          />
        )}
        </div>
    </div>

  );
}

export default Reporting;
