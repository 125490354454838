import React, { useState, useEffect } from 'react';
import StatBox from './StatBox';

const StatBoxWrapper = ({ data }) => {

    return (
        <div className="stat-boxes-container">
            {data.map((item, index) => (
                <StatBox key={index} title={item.title} value={item.value} />
            ))}
        </div>
    )
}

export default StatBoxWrapper;
